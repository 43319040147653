<template>
    <div>
        <el-dialog title="消息模版新增" :visible.sync="visible" :close-on-click-modal="false" width="40%">
            <el-form :model="newsForm" ref="newsForm" size="mini">
                <el-form-item label="企业名称（支持模糊搜索）" prop="enterpriseId" :rules="[{required:true, message: '请选择企业名称', trigger: 'change'}]">
                    <el-select v-model="newsForm.enterpriseId" filterable @click.native="companyIdChange" style="width:100%">
                       <el-option v-for="item in comanyList" :key="item.id" :value="item.id" :label="item.name"></el-option>
                   </el-select>
               </el-form-item>
               <el-form-item label="模版名称" prop="name" :rules="[{required:true, message: '请输入模版名称', trigger: 'bulr'}]">
                    <el-input v-model="newsForm.name"></el-input>
                </el-form-item>
                <el-form-item label="消息类型" prop="type" :rules="[{required:true, message: '请输入消息类型', trigger: 'bulr'}]">
                    <el-input v-model="newsForm.type"></el-input>
                </el-form-item>
                <el-form-item label="消息模版ID" prop="templateId" :rules="[{required:true, message: '请输入消息模版ID', trigger: 'bulr'}]">
                    <el-input v-model="newsForm.templateId"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="state" :rules="[{required:true, message: '请选择启用状态', trigger: 'bulr'}]">
                   <el-radio v-model="newsForm.state" :label="1">启用</el-radio>
                   <el-radio v-model="newsForm.state" :label="2">禁用</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="newsSubmit('newsForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-form :model="params" size="mini" :inline="true">
            <el-form-item label="企业名称">
                <el-input v-model="params.enterpriseName"></el-input>
            </el-form-item>
            <el-button type="primary" size="mini" @click="loadNewsList(true)">查询</el-button>
            <el-button type="danger" size="mini" icon="el-icon-plus" @click="add(true)">添加消息模版</el-button>
        </el-form>
        <el-table :data="tableData" stripe>
            <el-table-column align="center" type="index" width="55" label="序号"></el-table-column>
            <el-table-column prop="name" label="模板名称"></el-table-column>
            <el-table-column prop="enterpriseName" label="企业名称"></el-table-column>
            <el-table-column prop="type" label="类型"></el-table-column>
            <el-table-column prop="templateId" label="消息ID"></el-table-column>
            <el-table-column prop="state" label="状态">
                <template slot-scope="scope">
                    <template v-if="scope.row.state === 1">
                        <el-tag>启用</el-tag>
                    </template>
                    <template v-if="scope.row.state === 2">
                        <el-tag type="danger">禁用</el-tag>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="apply(scope.row)">{{scope.row.state === 1 ? '禁用':'启用'}}</el-button>
                    <el-button type="text" size="mini" @click="add(scope.row)">修改</el-button>
                    <el-button type="text" size="mini" @click="del(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-pagination
            class="page-footer"
            background
            layout="total,prev,pager,next,sizes,jumper"
            :total="total"
            :page-sizes="[10,20,30,40,50]"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            />
        </el-row>

    </div>
</template>

<script>
import { 
    getNewsList,
    newsDel,
    newsAdd,
    newsEdit
} from '@/api/news_api'
import store from '@/store'
export default {
    name: "templateType",
    data() {
        return {
            tableData:[],
            total:0,
            params:{
                enterpriseName:'',
                pageNum:1,
                pageSize:10
            },
            newsForm:{
                name:'',
                type:'',
                templateId:'',
                state:'',
                enterpriseId:''
            },
            visible:false,
            id:'',
            comanyList:[]
        };
    },
    mounted(){
        this.loadNewsList(true);
    },
    methods: {
        async loadNewsList(flag){
            if(flag){
                this.params.pageNum = 1;
            }
            let req = await getNewsList(this.params);
            if(req.code === 200){
                this.tableData = req.data.list;
                this.total = req.data.total;
            }
        },
        handlePageChange(page) {
           this.tempTypeParams.pageNum = page;
            this.loadTypeList(false);
        },
        handleSizeChange(page) {
            this.tempTypeParams.pageSize = page;
            this.loadTypeList(false);
        },
        async add(row){
            if(row === true){
                this.id = "";
                this.newsForm={
                    name:'',
                    type:'',
                    templateId:'',
                    state:'',
                    enterpriseId:''
                }
                this.comanyList =[];
                this.visible = true;
            }else{
                this.comanyList = [];
                this.comanyList = await store.dispatch('user/getSysConmanyList');
                this.id = row.id;
                this.$nextTick(()=>{
                    this.newsForm={
                        name:row.name,
                        type:row.type,
                        templateId:row.templateId,
                        state:row.state,
                        enterpriseId:row.enterpriseId
                    }
                });
                this.visible = true;
            }
        },
        async companyIdChange(){
            this.comanyList = [];
            this.comanyList = await store.dispatch('user/getSysConmanyList');
        },
        //删除类别
        del(row){
            this.$confirm('是否删除？', "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let params = {id:row.id}
                let req = await newsDel(params);
                if(req.code === 200){
                    this.$message.success("操作成功");
                    this.visible = false;
                    this.loadNewsList(false);
                }
            }).catch((error) => {});
        },
        //类别提交
        newsSubmit(formName){
            this.$refs[formName].validate(async(valid) => {
                if (valid) {
                    let params = {}
                    if(this.id){
                        params.id = this.id;
                    }
                    params.name = this.newsForm.name;
                    params.type = this.newsForm.type;
                    params.templateId = this.newsForm.templateId;
                    params.state = this.newsForm.state;
                    params.enterpriseId = this.newsForm.enterpriseId;
                    let req = this.id === '' ? await newsAdd(params) : await newsEdit(params);
                    if(req.code === 200){
                        this.$message.success('操作成功');
                        this.visible = false;
                        this.loadNewsList(false);
                    }
                } else {
                    return false;
                }
            });
        },
        apply(row){
            let state = row.state === 1 ? 2 : 1;
           let _stateText = row.state === 2 ? '确认禁用模版' : '确认启用模版';
           this.$confirm(_stateText, "友情提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(async () => {
              let params = {id:row.id,state:state,name:row.name,type:row.type,templateId:row.templateId,enterpriseId:row.enterpriseId};
              let req = await newsEdit(params);
              if(req.code ===200){
                  this.$message.success("操作成功");
                  this.loadNewsList(false);
              }
          }).catch(() => {});
        }
    }
};
</script>

