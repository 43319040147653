<template>
    <div>
      <div class="darg-flex">
        <div id="PDFWARPER" class="pdf-warpper"></div>
      </div>
      <div class="draw-warpper clearfix">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>温馨提示</span>
              <p class="texts">1）用鼠标移到右侧指定内容上，拖动对应内容到左侧pdf文件指定位置上即可（拖动的内容不能超出pdf边框范围）。</p>
              <p class="texts">2）拖到pdf上还可以继续拖动调整占位符位置。也可以删除重新在拖动。</p>
              <p class="texts">3）模版必须配置 公司签章、个人签章、手签位置 三个签字！</p>
              <p class="texts">4）拖动的内容请仔细查看位置的摆放，如果拖动的内容离pdf的内容距离差距很远，会影响整体的视觉效果！</p>
          </div>
          <div class="darw-li zhang"><img :src="aAhang" style="height:100px;" id="draw3"></div>
          <div class="darw-li names">
            <div class="keys">
              <i class="el-icon-s-custom"></i><label class="labels" id="draw1">个人签章</label>
            </div>
            <div class="keys">
              <i class="el-icon-s-custom"></i><label class="labels" id="draw2">手签位置</label>
            </div>
          </div>
          <el-button style="width:100%" @click="submitDraw" type="primary">提交配置</el-button>
          <el-button style="width:100%;margin-left: 0;margin-top: 10px;" @click="closeTo" type="danger">返回</el-button>
        </el-card>
      </div>
     
    </div>
  </template>
  
  <script>
  import $ from 'jquery'
  import 'jquery-ui-dist/jquery-ui'
  import 'jquery-ui-dist/jquery-ui.css'
  const PDFJS = require('pdfjs-dist')
  PDFJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.min')
  import a_ahang from "@/assets/a_zhang.png"
  import { templateDetails,editTemplate } from "@/api/template_api"
  export default {
      name: "darg",
      data() {
          return {
            aAhang:a_ahang,
            pdfDoc:null,
            sumHei:0,
            drwaFlat:{draw1:false,draw2:false,draw3:false}
          };
      },
      mounted(){
          const _self = this;
          _self.loadFile(_self.$route.query.id);
          _self.initDraw();
          window.removeDraw = this.removeDraw;
      },
      methods: {
          initDraw(){
            let _self = this;
            let _data = _self.drwaFlat;
            for (let k in _data) {
              $('#'+k).accordion();
              $('#'+k).draggable({
                  helper: "clone",
                  cursor: "move",
                  cancel: "a.ui-icon",
                  revert: "invalid",
                  start: function (event, ui) {
                      _self.drwaFlat[k] = true;
                  },
                  stop: function (event, ui) {
                  }
              });
            }
            $("#PDFWARPER").droppable({
              drop: function (event, ui) {
                  var name = ui.draggable[0].innerHTML;
                  var offsetY = parseInt(ui.offset.top - $(this).offset().top);//当前几页
                  var arr = [];
                  var index;
                  var pageId;
                  var dataHei;
                  $("#PDFWARPER .svg-cavas").each(function () {
                      arr.push({
                          "dataHei": $(this).attr("data"),
                          "pageId": $(this).attr("id")
                      })
                  })
                  
                  for (var i = 0; i < arr.length; i++) {
                      if (arr[i].dataHei > offsetY) {
                          dataHei = arr[i].dataHei;
                          pageId = arr[i].pageId;
                          index = (i + 1);
                          break;
                      }
                  }
                  var posX = parseInt(ui.offset.left - $(".svg-cavas:eq(" + (index - 1) + ")").offset().left);
                  var posY = parseInt(ui.offset.top - $(".svg-cavas:eq(" + (index - 1) + ")").offset().top);
                  if (dataHei > offsetY) {
                    let _data = _self.drwaFlat;
                    for (let k in _data) {
                      if(_data[k] === true){
                        var thisids = 0;
                        if(k.includes('1')){
                          thisids = 1;
                        }else if(k.includes('2')){
                          thisids = 2;
                        }else if(k.includes('3')){
                          thisids = 3;
                        }
                        _self.drwaFlat[k] = false;
                        _self.domCreate(posX, posY, name, thisids, pageId, index);
                      }
                    }
                  }
                }
            });
          },
          domCreate(posX, posY, name, thisids, pageId, index) {
             let _str = '';
             let tag = '';
             let className = '';
             let _posy = 0;
            if(thisids === 1){
              name = "个人签章";
              tag = 'personal';
              className = 'create-text';
              _posy = 15;
              _str = '<div class="create-draws-sign">' + name + '</div>';
            }else if(thisids === 2){
              name = "手签位置";
              tag = 'username';
              className = 'create-text';
              _posy = 15;
              _str = '<div class="create-draws-sign">' + name + '</div>';
            }else if(thisids === 3){
              tag = 'company';
               className = 'create-imgs';
               _posy = 25;
               _str = '<img src='+a_ahang+' class="create-draws-img">';
            }
            
            var elemdraw = $('<div class="create-draws '+className+'" data-tag=' + tag + ' data-x=' + posX + ' data-y=' + (posY+_posy) + ' data-page=' + pageId + ' style="position: absolute;left:' + posX + 'px;top:' + posY + 'px">' +
                '<div class="remove-dom" onclick="removeDraw(this)">x</div>' +
                ''+_str+'</div>').draggable({
                containment: $(".svg-cavas:eq(" + (index - 1) + ")"),
                start: function (event, ui) {
                },
                stop: function (event, ui) {
                    posX = parseInt(ui.offset.left - $(".svg-cavas:eq(" + (index - 1) + ")").offset().left);
                    posY = parseInt(ui.offset.top - $(".svg-cavas:eq(" + (index - 1) + ")").offset().top);
                    $(this).attr({"data-x": posX, "data-y": (posY+_posy)});
                }
            })
            $("#PDFWARPER").find(".svg-cavas:eq(" + (index - 1) + ")").append(elemdraw);
        },
        removeDraw(obj) {
          $(obj).parent(".create-draws").remove();
        },
        async loadFile (id) {
          const _self = this;
          let params = {id:id};
          let req = await templateDetails(params);
          if(req.code === 200){
            const _url = req.data.url;
            let placeholders = req.data.placeholder;
            PDFJS.getDocument(_url).then((pdf) => {
              _self.pdfDoc = pdf;
              for (var i = 1; i <= pdf.numPages; i++) {
                var id = "canvas" + i;
                var html = "<div style='position: relative;margin:0 auto 10px' id=" + i + " class='svg-cavas'>" +
                    "<canvas style='border:1px solid #bcbdbd;' id='" + id + "'></canvas>" +
                    "</div>";
                $("#PDFWARPER").append(html);
                _self.renderPage(placeholders,i)
              }
            })
          }
        },
        renderPage (placeholders,num) {
            this.pdfDoc.getPage(num).then((page) => {
              var scale = 1.0;
              var viewport = page.getViewport(scale);
              var id = "canvas" + num;
              var canvas = document.getElementById(id);
              var context = canvas.getContext('2d');
              if(viewport.width > 900){
                scale = 900 / viewport.width;
                viewport = page.getViewport(scale);
                canvas.height = viewport.height;
                canvas.width = viewport.width;
              }else{
                canvas.width = viewport.width;
                canvas.height = viewport.height;
              }
              this.sumHei = this.sumHei + canvas.height;
              $(".svg-cavas:eq(" + (num - 1) + ")").css({"width": canvas.width, "height": canvas.height});
              $(".svg-cavas:eq(" + (num - 1) + ")").attr("data", this.sumHei);
              this.loadTemp(placeholders,num);
              let renderContext = {
                  canvasContext: context,
                  viewport: viewport
              }
              page.render(renderContext)
            })
          },
          loadTemp(placeholders,num){
            if(!placeholders){
              return;
            }
            let _items = JSON.parse(placeholders);
            for(var i=0,items;items=_items[i++];){
              if (items.page == num) {
                
                let _str = '';
                let tag = '';
                let name = '';
                let className = '';
                let posy = 0;
                let datay = 0;
                if(items.tag === 'personal'){
                  name = "个人签章";
                  tag = 'personal';
                  className = 'create-text';
                  posy = 15;
                  _str = '<div class="create-draws-sign">' + name + '</div>';
                }else if(items.tag === 'username'){
                  name = "手签位置";
                  tag = 'username';
                  className = 'create-text';
                  posy = 15;
                  _str = '<div class="create-draws-sign">' + name + '</div>';
                }else if(items.tag === 'company'){
                  tag = 'company';
                  className = 'create-imgs';
                  posy = 25;
                  _str = '<img src='+a_ahang+' class="create-draws-img">';
                }
                var elemdraw = $('<div class="create-draws '+className+'" data-tag=' + tag + ' data-x=' + items.x + ' data-y=' + items.y + ' data-page=' + num + ' style="position: absolute;left:' + items.x + 'px;top:' + (items.y-posy) + 'px">' +
                    '<div class="remove-dom" onclick="removeDraw(this)">x</div>' +
                    ''+_str+'</div>').draggable({
                    containment: $(".svg-cavas:eq(" + (num - 1) + ")"),
                    start: function (event, ui) {
                    },
                    stop: function (event, ui) {
                        let posX = parseInt(ui.offset.left - $(".svg-cavas:eq(" + (num - 1) + ")").offset().left);
                        let posY = parseInt(ui.offset.top - $(".svg-cavas:eq(" + (num - 1) + ")").offset().top);
                        $(this).attr({"data-x": posX, "data-y": (posY+posy)});
                    }
                })
                $("#PDFWARPER").find(".svg-cavas:eq(" + (num - 1) + ")").append(elemdraw);
              }
            }
          },
          async submitDraw(){
            var $pdf = $(".create-draws");
            var placeholders = [];
            for (var i = 0; i < $pdf.length; i++) {
              placeholders.push({
                  "page": parseInt($($pdf[i]).attr("data-page")),
                  "x": parseInt($($pdf[i]).attr("data-x")),
                  "y": parseInt($($pdf[i]).attr("data-y")),
                  "signY": parseInt($($pdf[i]).attr("data-y")) + parseInt(50),
                  "tag": $($pdf[i]).attr("data-tag"),
              })
           }
           let params = {};
           params.placeholders = placeholders
           params.id =this.$route.query.id
            let req = await editTemplate(params);
            if(req.code === 200){
              this.$message.success('配置成功');
              this.closeTo();
            }
          },
          closeTo(){
            this.$router.push({path:"/template/list",name:"TemplateList"});
          }
      }
  };
  </script>
  <style>
  .darg-flex{
    margin-right: 180px;
  }
  .pdf-warpper{
    margin: 0 auto;
    width: 900px;
    /*overflow-y: scroll;
    padding: 0 10px;
    height:calc(100vh - 160px);*/
  }
  .draw-warpper{
    width: 300px;
    position: fixed;
    bottom: 26px;
    right: 32px;
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    z-index: 99;
  }
 
  .names{
    margin-bottom: 10px;
    display: flex;
  }
  .keys{
    border:3px solid #f00;
    color: #f00;
    padding: 8px;
    flex: 1;
    margin-left: 5px;
    margin-right:5px;
    cursor: move;
  }
  .labels{
    flex: 1;
    cursor: move;
  }
  .names:last-child{
    margin-bottom: 0;
  }
  .zhang{
    margin-bottom: 10px;
  }
  .create-text{
    filter: alpha(opacity=80);
    opacity: 0.8;
    border: 1px solid #f00;
    background: #fff;
    z-index: 4;
    width: 84px;
    height: 30px;
  }
  .create-imgs{
    filter: alpha(opacity=80);
    opacity: 0.8;
    border: 1px solid #f00;
    background: #fff;
    z-index: 4;
    width: 84px;
    height: 50px;
  }
  .remove-dom {
    position: absolute;
      right: 0;
      top: 0px;
      z-index: 3;
      text-decoration: none;
      width: 18px;
      height: 18px;
      line-height: 13px;
      cursor: pointer;
      background: #f00;
      border-bottom-left-radius: 50px;
      color: #fff;
      font-size: 14px;
      padding-left: 7px;
  }
  .create-draws-sign{
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    cursor: move;
  }
  .create-draws-img{
    width: 74px;
    height: 50px;
    display: block;
    cursor: move;
  }
  .texts{
    color: rgb(245, 108, 108);
    line-height: 22px;
    font-size: 14px;
    text-align: justify;
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  .texts:last-child{
    border-bottom: 0;
  }
  </style>
  