<template>
    <div>
       <el-form :model="params" size="mini" :inline="true">
        <el-form-item label="个人姓名">
            <el-input v-model="params.queryUserName"></el-input>
        </el-form-item>
        <el-form-item label="签约状态">
            <el-select v-model="params.queryState">
                <el-option v-for="item in stateData" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="身份证">
            <el-input v-model="params.queryIdCard"></el-input>
        </el-form-item>
        <el-form-item label="是否过期">
            <el-select v-model="params.expire">
                <el-option v-for="item in expireData" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="删除日期">
            <el-date-picker
            v-model="time"
            type="datetimerange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-search" @click="loadList(true)">查询</el-button>
            <el-button type="primary" size="mini" icon="el-icon-setting"  @click="restForm">重置</el-button>
        </el-form-item>
       </el-form>
        <el-table :data="tableData" stripe ref="table" :cell-style="columnStyle">
            <el-table-column prop="name" label="合同名称"></el-table-column>
            <el-table-column prop="tempTypeName" label="合同类别"></el-table-column>
            <el-table-column prop="stateName" label="状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.state===3">{{scope.row.stateName}}</el-tag>
                    <el-tag v-else-if="scope.row.state===1" type="success">{{scope.row.stateName}}</el-tag>
                    <el-tag v-else-if="scope.row.state===2" type="warning">{{scope.row.stateName}}</el-tag>
                    <el-tag v-else-if="scope.row.state===4" type="danger">{{scope.row.stateName}}</el-tag>
                    <el-tag v-else-if="scope.row.state===5" type="warning">{{scope.row.stateName}}</el-tag>
                    <el-tag v-else-if="scope.row.state===6" type="danger">{{scope.row.stateName}}</el-tag>
                    <el-tag v-else-if="scope.row.state===7" type="warning">{{scope.row.stateName}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="tempName" label="模版名称"></el-table-column>
            <el-table-column prop="realName" label="微信用户"></el-table-column>
            <el-table-column prop="nickName" label="微信昵称"></el-table-column>
            <el-table-column prop="idCard" label="身份证"></el-table-column>
             <el-table-column prop="url" label="合同链接">
                <template slot-scope="scope">
                   <el-button type="text" @click="openUrl(scope.row)" size="mini">预览</el-button>
                </template>
             </el-table-column>
             <el-table-column prop="signTime" label="签约日期"></el-table-column>
             <el-table-column prop="deleteDate" label="删除日期"></el-table-column>
            <el-table-column prop="createTime" label="创建日期"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <template v-if="userType ==='3'">
                        <el-button type="text" size="mini" @click="del(scope.row)">恢复</el-button>
                    </template>
                    <template v-if="userType ==='3'">
                        <el-button v-if="scope.row.expire === 2" type="danger" size="mini">已过期</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-pagination
            class="page-footer"
            background
            layout="total,prev,pager,next,sizes,jumper"
            :total="total"
            :page-sizes="[10,20,30,40,50,100]"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            />
        </el-row>
    

    </div>
</template>

<script>
import { initContractList,contractNewDel} from '@/api/contract_api'
import store from '@/store'
export default {
    name: "recovery",
    data() {
        return {
            pickerOptions: {
            shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近一个月',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近三个月',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
                }
            }]
            },
            stateData:[
                {value:'',label:'全部'},
                {value:1,label:'已签'},
                {value:2,label:'待签'},
                {value:3,label:'待审核'},
                {value:4,label:'禁用'},
                {value:5,label:'签署中'},
                {value:6,label:'签署失败'},
                {value:7,label:'未登记'}
            ],
            expireData:[
                {value:'',label:'全部'},{value:2,label:'已过期'}
            ],
            tableData:[],
            total:0,
            time:[],
            params:{
                queryUserName:'',
                queryState:'',
                queryIdCard:'',
                deleteEnd:'',
                expire:'',
                deleteStart:'',
                deleteState:2,
                pageNum:1,
                pageSize:10
            },
        };
    },
    computed: {
        setUserType(){
            return this.$store.getters.userId
        },
        userType(){
            return sessionStorage.getItem('userType')
        }
    },
    watch: {
        setUserType: {
            handler(newValue){  
               this.loadList(true);
            }
        }
    },
    mounted(){
        this.loadList(true);
    },
    methods: {
        columnStyle({ row, column, rowIndex, columnIndex }) {
            if (row.expire === 2) {
                if(column.label === '操作'){
                    return 'background: #ebd4d4;'
                }
            } 
        },
        async loadList(flag){
            if(flag){
                this.params.pageNum = 1;
            }
            const userType = sessionStorage.getItem('userType');
            if(userType === '1'){
                if(this.$store.getters.userId){
                    this.params.enterprises = [this.$store.getters.userId];
                }else{
                    const canmanyList = await store.dispatch('user/getUserIdCanmanyList',sessionStorage.getItem('id'));
                    this.params.enterprises = canmanyList.map(v=>v.enterpriseId);
                }
            }else if(userType === '2'){
                if(this.$store.getters.userId){
                    this.params.enterprises = [this.$store.getters.userId]
                }else{
                    const canmanyList = await store.dispatch('user/getUserIdCanmanyList',sessionStorage.getItem('id'));
                    this.params.enterprises = canmanyList.map(v=>v.enterpriseId);
                }
            }
            if(this.time.length !==0){
                this.params.deleteStart = this.time[0];
                this.params.deleteEnd = this.time[1];
            }
            let req = await initContractList(this.params);
            if(req.code === 200){
                this.tableData = req.data.list;
                this.total = req.data.total;
            }
        },
        handlePageChange(page) {
           this.params.pageNum = page;
            this.loadList(false);
        },
        handleSizeChange(page) {
            this.params.pageSize = page;
            this.loadList(false);
        },
        openUrl(item){
            if(!item.url){return;}
            var form = document.createElement('form');
           if(item.state === 1){
                form.action = item.downUrl;
            }else{
                form.action = item.url;
            }
            form.target = '_blank';
            form.method = 'get';
            document.body.appendChild(form);
            form.submit();
        },
        restForm(){
            this.params = {
                queryName:'',
                queryState:'',
                queryIdCard:'',
                deleteEnd:'',
                deleteStart:'',
                expire:'',
                deleteState:2,
                pageNum:1,
                pageSize:10
            };
            this.time = [];
            this.loadList(true);
        },
        del(row){
            this.$confirm('确定恢复？', "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                const params = {
                    id:row.id,
                    deleteState:1
                }
                let req = await contractNewDel(params);
                if(req.code === 200){
                    this.$message.success("操作成功");
                    this.loadList(false);
                }
            }).catch((error) => {});
        }
    }
};
</script>
<style>
.flexs{
    display:flex;
}
.pages{
    flex: 1;
}
</style>
