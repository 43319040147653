import request from '@/utils/request'

/**企业管理 */
export function getSysConmanyList(data) {
    return request({
      url: '/api/enterprise/pageListEnterprise',
      method: 'get',
      params:data
    })
}
export function addSysConmany(data) {
    return request({
        url: '/api/enterprise/add',
        method: 'post',
        data
    })
}
export function appySysConmany(data) {
    return request({
        url: '/api/enterprise/ban',
        method: 'post',
        data
    })
}
export function editSysConmany(data) {
    return request({
        url: '/api/enterprise/update',
        method: 'post',
        data
    })
}

  
/**账号管理 */
export function getSysAccountList(data) {
    return request({
      url: '/api/account/pageListAccounts',
      method: 'get',
      params:data
    })
}
export function addSysAccount(data) {
    return request({
        url: '/api/account/add',
        method: 'post',
        data
    })
}
export function appySysAccount(data) {
    return request({
        url: '/api/account/ban',
        method: 'post',
        data
    })
}
export function editSysAccount(data) {
    return request({
        url: '/api/account/update',
        method: 'post',
        data
    })
}
export function buildSysAccount(data) {
    return request({
        url: '/api/account/bindToEp',
        method: 'post',
        data
    })
}
export function resetPwdSysAccount(data) {
    return request({
        url: '/api/account/resetPwd',
        method: 'get',
        params:data
    })
}