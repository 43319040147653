import request from '@/utils/request'

export function initConmanyList(data) {
    return request({
      url: '/api/company',
      method: 'post',
      data
    })
}
export function addConmany(data) {
    return request({
        url: '/api/company/save',
        method: 'post',
        data
    })
}
export function applyConmany(data) {
    return request({
        url: '/api/company',
        method: 'put',
        data
    })
}
export function companyAuth(id) {
    return request({
        url: `/api/companyAuth/${id}`,
        method: 'get'
    })
}