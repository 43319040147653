var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "draw-warpper clearfix" },
      [
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header"
              },
              [
                _c("span", [_vm._v("温馨提示")]),
                _c("p", { staticClass: "texts" }, [
                  _vm._v(
                    "1）用鼠标移到右侧指定内容上，拖动对应内容到左侧pdf文件指定位置上即可（拖动的内容不能超出pdf边框范围）。"
                  )
                ]),
                _c("p", { staticClass: "texts" }, [
                  _vm._v(
                    "2）拖到pdf上还可以继续拖动调整占位符位置。也可以删除重新在拖动。"
                  )
                ]),
                _c("p", { staticClass: "texts" }, [
                  _vm._v(
                    "3）模版必须配置 公司签章、个人签章、手签位置 三个签字！"
                  )
                ]),
                _c("p", { staticClass: "texts" }, [
                  _vm._v(
                    "4）拖动的内容请仔细查看位置的摆放，如果拖动的内容离pdf的内容距离差距很远，会影响整体的视觉效果！"
                  )
                ])
              ]
            ),
            _c("div", { staticClass: "darw-li zhang" }, [
              _c("img", {
                staticStyle: { height: "100px" },
                attrs: { src: _vm.aAhang, id: "draw3" }
              })
            ]),
            _c("div", { staticClass: "darw-li names" }, [
              _c("div", { staticClass: "keys" }, [
                _c("i", { staticClass: "el-icon-s-custom" }),
                _c("label", { staticClass: "labels", attrs: { id: "draw1" } }, [
                  _vm._v("个人签章")
                ])
              ]),
              _c("div", { staticClass: "keys" }, [
                _c("i", { staticClass: "el-icon-s-custom" }),
                _c("label", { staticClass: "labels", attrs: { id: "draw2" } }, [
                  _vm._v("手签位置")
                ])
              ])
            ]),
            _c(
              "el-button",
              {
                staticStyle: { width: "100%" },
                attrs: { type: "primary" },
                on: { click: _vm.submitDraw }
              },
              [_vm._v("提交配置")]
            ),
            _c(
              "el-button",
              {
                staticStyle: {
                  width: "100%",
                  "margin-left": "0",
                  "margin-top": "10px"
                },
                attrs: { type: "danger" },
                on: { click: _vm.closeTo }
              },
              [_vm._v("返回")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "darg-flex" }, [
      _c("div", { staticClass: "pdf-warpper", attrs: { id: "PDFWARPER" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }