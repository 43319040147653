import request from '@/utils/request'

export function getUserList(data) {
    return request({
      url: '/api/user',
      method: 'post',
      data
    })
  }
  //删除人员终端
  export function userDetails(id) {
    return request({
        url: `/api/user/${id}`,
        method: 'get',
    })
  }
  //导出
  export function userExport(data) {
    return request({
        url: '/api/export',
        method: 'post',
        data
    })
  }
  
