const state = {
  sign: false
}

const mutations = {
  SET_SIGN: (state, sign) => {
    state.sign = sign
  }
}

const actions = {
  resetRoutes({ commit }, data) {
    commit('SET_SIGN', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
