import request from '@/utils/request'

//登陆
export function login(query) {
  return request({
    url:'/api/login',
    method: 'post',
    params:query
  })
}
//获取权限
export function getPermission() {
  return request({
    url:'/api/info',
    method: 'get'
  })
}
//退出
export function logout() {
  return request({
    url: '/api/loginOut',
    method: 'get'
  })
}
//修改密码
export function editPwd(data) {
  return request({
    url: '/api/admin/pwd',
    method: 'put',
    data
  })
}

//上传图片
export function uploadPdf(data){
  return request({
    url:'/api/uploadFile',
    method:'post',
    data
  })
}
//获取通用数据
export function getContractTemp() {
  return request({
      url: '/api/pactTemp',
      isTrue:true,
      method: 'get',
  })
}
export function getComanyList() {
  return request({
    url: '/api/company',
    isTrue:true,
    method: 'get',
  })
}
export function getUserList(data) {
  return request({
    url: '/api/user/pact',
    isTrue:true,
    method: 'post',
    data
  })
}
export function getBatchCodeList() {
  return request({
    url: '/api/batchCode',
    isTrue:true,
    method: 'get',
  })
}
export function getTempTypeList(data) {
  return request({
    url: '/api/tempType/pageListTemplateType',
    method: 'post',
    data
  })
}

/* 用户类型关联企业 */
export function getUserIdCanmanyList(adminId){
  return request({
    url:`/api/enterprise/selectEnterpriseByAdminId?adminId=${adminId}`,
    isTrue:true,
    method:'get'
  })
}