<template>
    <div>
       <el-form :model="params" size="mini" :inline="true">
        <el-form-item label="企业名称">
            <el-input v-model="params.name"></el-input>
        </el-form-item>
        <el-button type="primary" size="mini" icon="el-icon-search" @click="loadList">查询</el-button>
        <el-button type="danger" size="mini" icon="el-icon-plus" @click="add">新增企业</el-button>
       </el-form>
        <el-table :data="tableData" stripe>
            <el-table-column prop="name" label="企业名称"></el-table-column>
            <el-table-column prop="nickName" label="企业简介"></el-table-column>
            <el-table-column prop="state" label="状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.state===1" type="success">启用</el-tag>
                    <el-tag v-else-if="scope.row.state===2" type="warning">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="apply(scope.row)">{{scope.row.state === 1 ? '禁用':'启用'}}</el-button>
                    <el-button type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-pagination
            class="page-footer"
            background
            layout="total,prev,pager,next,sizes,jumper"
            :total="total"
            :page-sizes="[10,20,30,40,50]"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            />
        </el-row>

        <el-dialog :visible.sync="visible" title="新增企业" width="700px" :close-on-click-modal="false">
            <el-form :model="ruleForm" ref="ruleForm" size="mini">
                <el-form-item label="初始平台企业账号（初始密码手机号后6位）" prop="account" :rules="[{required:true, message: '请输入初始平台企业账号', trigger: 'bulr'},
                { required: true, pattern: /^1(3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])\d{8}$/, message: '手机格式错误', trigger: 'blur' }]">
                    <el-input v-model="ruleForm.account" placeholder="请输入手机号" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="初始平台企业账号姓名" prop="accountName" :rules="[{required:true, message: '请输入初始平台企业账号姓名', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.accountName"></el-input>
                </el-form-item>
                <el-form-item label="平台企业名称" prop="name" :rules="[{required:true, message: '请输入平台企业名称', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="平台企业简称" prop="nickName" :rules="[{required:true, message: '请输入平台企业简称', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.nickName"></el-input>
                </el-form-item>
                <el-form-item label="平台企业小程序AppID" prop="appId" :rules="[{required:true, message: '请输入平台企业小程序AppID', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.appId"></el-input>
                </el-form-item>
                <el-form-item label="平台企业小程序key" prop="appSecret" :rules="[{required:true, message: '请输入平台企业小程序key', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.appSecret"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="state" :rules="[{required:true, message: '请选择启用状态', trigger: 'bulr'}]">
                    <el-radio-group v-model="ruleForm.state">
                        <el-radio :label="1">启用</el-radio>
                        <el-radio :label="2">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="平台企业logo" prop="logo" :rules="[{required:true, message: '请上传平台企业logo', trigger: 'change'}]">
                    <el-upload
                    class="avatar-uploader"
                    v-model="ruleForm.logo"
                    action="#"
                    accept="image/*"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="onChangeFile1"
                    :before-remove="beforeRemove">
                    <img v-if="ruleForm.logo" :src="ruleForm.logo" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="onFormSubmit('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="editVisible" title="编辑企业" width="700px" :close-on-click-modal="false">
            <el-form :model="ruleEditForm" ref="ruleEditForm" size="mini">
                <el-form-item label="平台企业名称" prop="name" :rules="[{required:true, message: '请输入平台企业名称', trigger: 'bulr'}]">
                    <el-input v-model="ruleEditForm.name"></el-input>
                </el-form-item>
                <el-form-item label="平台企业简称" prop="nickName" :rules="[{required:true, message: '请输入平台企业简称', trigger: 'bulr'}]">
                    <el-input v-model="ruleEditForm.nickName"></el-input>
                </el-form-item>
                <el-form-item label="平台企业logo" prop="logo" :rules="[{required:true, message: '请上传平台企业logo', trigger: 'change'}]">
                    <el-upload
                    class="avatar-uploader"
                    v-model="ruleEditForm.logo"
                    action="#"
                    accept="image/*"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="onChangeFile2"
                    :before-remove="beforeRemove">
                    <img v-if="ruleEditForm.logo" :src="ruleEditForm.logo" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="editVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="onFormSubmit('ruleEditForm')">确 定</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import { getSysConmanyList,addSysConmany,appySysConmany,editSysConmany} from '@/api/sys_manany_api'
import { uploadPdf } from '@/api/common_api'
export default {
    name: "sysComany",
    data() {
        return {
            tableData:[],
            total:0,
            params:{
                name:'',
                pageNum:1,
                pageSize:10
            },
            visible:false,
            editVisible:false,
            id:'',
            ruleForm:{
                account:'',
                accountName:'',
                appId:'',
                appSecret:'',
                logo:'',
                name:'',
                nickName:'',
                state:''
            },
            ruleEditForm:{
                logo:'',
                name:'',
                nickName:''
            }
        };
    },
    mounted(){
        this.loadList();
    },
    methods: {
        async loadList(){
            let req = await getSysConmanyList(this.params);
            if(req.code === 200){
                this.tableData = req.data.list;
                this.total = req.data.total;
            }
        },
        handlePageChange(page) {
           this.params.pageNum = page;
            this.loadList();
        },
        handleSizeChange(page) {
            this.params.pageSize = page;
            this.loadList();
        },
        async onChangeFile1(file,fileList){
            let formData = new FormData();
            formData.append('file',file.raw);
            let req = await uploadPdf(formData);
            if(req.code === 200){
                this.ruleForm.logo = req.data;
            }
        },
        async onChangeFile2(file,fileList){
            let formData = new FormData();
            formData.append('file',file.raw);
            let req = await uploadPdf(formData);
            if(req.code === 200){
                this.ruleEditForm.logo = req.data;
            }
        },
        // 移除文件之前
        beforeRemove(file, fileList) {
            return this.$message.error(`${file.name}移除`)
        },
        apply(row){
            let text = row.state === 1 ? '确定禁用！' : '确定启用！';
            let state = row.state === 1 ? 2 : 1;
            this.$confirm(text, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let params = {id:row.id,state:state}
                let req = await appySysConmany(params);
                if(req.code === 200){
                this.$message.success("操作成功");
                this.loadList();
                }
            }).catch(() => {});
        },
        add(){
            this.visible = true;
            this.id = '';
            this.ruleForm = {
                account:'',
                accountName:'',
                appId:'',
                appSecret:'',
                logo:'',
                name:'',
                nickName:'',
                state:''
            }
        },
        edit(row){
            this.ruleEditForm = {
                logo:row.logo,
                name:row.name,
                nickName:row.nickName
            }
            this.id = row.id;
            this.editVisible = true;
        },
        onFormSubmit(formName){
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let text = '';
                    let params = {};
                    if(this.id){
                        text = '修改企业成功';
                        params.id = this.id;
                        params.logo = this.ruleEditForm.logo;
                        params.name = this.ruleEditForm.name;
                        params.nickName = this.ruleEditForm.nickName;
                    }else{
                        text = '新增企业成功';
                        params.account = this.ruleForm.account;
                        params.accountName = this.ruleForm.accountName;
                        params.appId = this.ruleForm.appId;
                        params.appSecret = this.ruleForm.appSecret;
                        params.logo = this.ruleForm.logo;
                        params.name = this.ruleForm.name;
                        params.nickName = this.ruleForm.nickName;
                        params.state = this.ruleForm.state;
                    }
                let req = this.id ? await editSysConmany(params) : await addSysConmany(params);
                if(req.code === 200){
                    this.$message.success(text);
                    if(this.id){
                        this.editVisible = false;
                    }else{
                        this.visible = false;
                    }
                    this.loadList();
                }
                } else {
                    return false;
                }
            });
        }
    }
};
</script>
<style>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
