<template>
    <div>
       <el-form :model="params" size="mini" :inline="true">
        <el-form-item label="账号名称">
            <el-input v-model="params.keyword" placeholder="支持模糊查询"></el-input>
        </el-form-item>
        <el-form-item label="账号类型">
            <el-select v-model="params.type">
                <el-option v-for="item in stateData" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
        </el-form-item>
        <el-button type="primary" size="mini" icon="el-icon-search" @click="loadList(true)">查询</el-button>
        <el-button type="danger" size="mini" icon="el-icon-plus" @click="add">新增账号</el-button>
       </el-form>
        <el-table :data="tableData" stripe>
            <el-table-column prop="userName" label="账号"></el-table-column>
            <el-table-column prop="nickName" label="姓名"></el-table-column>
            <el-table-column prop="type" label="账号类型">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type===2">管理员</el-tag>
                    <el-tag v-else-if="scope.row.type===3">平台企业</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.state===1" type="success">启用</el-tag>
                    <el-tag v-else-if="scope.row.state===2" type="warning">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="enterprise" label="平台企业"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="apply(scope.row)">{{scope.row.state === 1 ? '禁用':'启用'}}</el-button>
                    <el-button type="text" size="mini" v-if="scope.row.state === 1" @click="setPwd(scope.row)">重置密码</el-button>
                    <el-button type="text" size="mini" v-if="scope.row.state === 1" @click="bindComany(scope.row)">绑定企业</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-pagination
            class="page-footer"
            background
            layout="total,prev,pager,next,sizes,jumper"
            :total="total"
            :page-sizes="[10,20,30,40,50]"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            />
        </el-row>

        <el-dialog :visible.sync="visible" title="新增账号" width="500px" :close-on-click-modal="false">
            <el-form :model="ruleForm" ref="ruleForm" size="mini">
                <el-form-item label="账号（密码为手机号后四位）" prop="userName" :rules="[{required:true, message: '请输入名称', trigger: 'bulr'},
                { required: true, pattern: /^1(3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])\d{8}$/, message: '手机格式错误', trigger: 'blur' }]">
                    <el-input v-model="ruleForm.userName" placeholder="请输入手机号" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="name" :rules="[{required:true, message: '请输入账号', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="state" :rules="[{required:true, message: '请选择启用状态', trigger: 'bulr'}]">
                    <el-radio-group v-model="ruleForm.state">
                        <el-radio :label="1">启用</el-radio>
                        <el-radio :label="2">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="账号类型" prop="type" :rules="[{required:true, message: '请选择账号类型', trigger: 'bulr'}]">
                    <el-radio-group v-model="ruleForm.type">
                        <el-radio :label="2">管理员</el-radio>
                        <el-radio :label="3">平台企业</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="onFormSubmit('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="buildVisible" title="绑定企业" width="500px" :close-on-click-modal="false">
            <el-form :model="ruleBuildForm" ref="ruleBuildForm" size="mini">
                <el-form-item :label="accountType===2?'企业名称（支持多选）':'企业名称'" prop="targetIds" :rules="[{required:true, message: '请绑定企业名称', trigger: 'bulr'}]">
                    <el-select v-model="ruleBuildForm.targetIds" style="width:100%" @click.native="buildComanyChange" filterable :multiple="accountType===2?true:false" placeholder="请选择">
                        <el-option
                          v-for="item in options"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="buildVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="onBuildFormSubmit('ruleBuildForm')">确 定</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    //http://192.168.1.82:8089/api/swagger-ui.html#/%E8%B4%A6%E5%8F%B7%E7%AE%A1%E7%90%86/bindToEpUsingPOST
import { 
    getSysAccountList,
    addSysAccount,
    appySysAccount,
    editSysAccount,
    buildSysAccount,
    resetPwdSysAccount
} from '@/api/sys_manany_api'
import store from '@/store'
export default {
    name: "sysAccount",
    data() {
        return {
            tableData:[],
            total:0,
            stateData:[
                {value:'',label:'全部'},{value:2,label:'管理员'},{value:3,label:'平台企业'}
            ],
            options:[],
            params:{
                keyword:'',
                type:'',
                pageNum:1,
                pageSize:10
            },
            visible:false,
            id:'',
            ruleForm:{
                name:'',
                state:'',
                type:'',
                userName:''
            },
            buildVisible:false,
            accountType:3,
            options:[],
            adminId:'',
            ruleBuildForm:{
                targetIds:''
            }
        };
    },
    mounted(){
        this.loadList(true);
    },
    methods: {
        async loadList(falg){
            if(falg){
                this.params.pageNum = 1;
                this.params.pageSize = 10;
            }
            let req = await getSysAccountList(this.params);
            if(req.code === 200){
                this.tableData = req.data.list;
                this.total = req.data.total;
            }
        },
        handlePageChange(page) {
           this.params.pageNum = page;
            this.loadList(false);
        },
        handleSizeChange(page) {
            this.params.pageSize = page;
            this.loadList(false);
        },
        apply(row){
            let text = row.state === 1 ? '禁用状态，绑定企业及重置密码功能不能操作！确定禁用！' : '确定启用！';
            let state = row.state === 1 ? 2 : 1;
            this.$confirm(text, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let params = {id:row.id,state:state}
                let req = await appySysAccount(params);
                if(req.code === 200){
                this.$message.success("操作成功");
                this.loadList(false);
                }
            }).catch(() => {});
        },
        add(){
            this.visible = true;
            this.id = '';
            this.ruleForm = {
                name:'',
                state:'',
                type:'',
                userName:''
            }
        },
        edit(row){
            this.ruleForm = {
                name:row.name,
                state:row.state,
                type:row.type,
                userName:row.userName
            }
            this.id = row.id;
            this.visible = true;
        },
        async buildComanyChange(){
            this.options = [];
            this.options = await store.dispatch('user/getSysConmanyList',{});
        },
        onFormSubmit(formName){
            this.$refs[formName].validate(async (valid) => {
            if (valid) {
                let text = '';
                if(this.id){
                    text = '修改企业成功';
                    this.ruleForm.id = this.id;
                }else{
                    text = '新增企业成功';
                }
               let req = this.id ? await editSysAccount(this.ruleForm) : await addSysAccount(this.ruleForm);
               if(req.code === 200){
                   this.$message.success(text);
                   this.visible = false;
                   this.loadList(true);
               }
            } else {
                return false;
            }
            });
        },
        setPwd(row){
            this.$confirm('是否将该账号密码重置为手机号后6位？', "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let params = {id:row.id}
                let req = await resetPwdSysAccount(params);
                if(req.code === 200){
                this.$message.success("操作成功");
                this.loadList(false);
                }
            }).catch(() => {});
        },
        bindComany(row){
            this.adminId = row.id;
            this.accountType = row.type;
            this.ruleBuildForm.targetIds = '';
            if(row.type === 2){
                this.ruleBuildForm.targetIds = [];
            }else{
                this.ruleBuildForm.targetIds = '';
            }
            this.buildVisible = true;
        },
        onBuildFormSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm('确认绑定', "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(async () => {
                        let params = {};
                        if(this.accountType === 2){
                            params.adminId = this.adminId;
                            params.targetIds = this.ruleBuildForm.targetIds;
                        }else{
                            params.adminId = this.adminId;
                            params.targetIds = [this.ruleBuildForm.targetIds];
                        }
                        let req = await buildSysAccount(params);
                        if(req.code === 200){
                            this.$message.success("操作成功");
                            this.buildVisible = false;
                            this.loadList(false);
                        }
                    }).catch(() => {});
                }else {
                    return false;
                }
            });
            
        }
    }
};
</script>

