// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/user-bg.jpeg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.cyan{\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n  background-size: cover;\n  padding: 15px 0 15px 15px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  color: #fff;\n}\n.cyan-inner{\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  margin-left: 10px;\n}\n.cyan-inner label{\n  display: block;\n}\n.cyan-inner p{\n  margin-top: 10;\n  margin-bottom: 0;\n  font-size: 12px;\n  font-weight: bold;\n}\n.head-img{\n  width: 52px;\n  height: 52px;\n  border-radius: 50%;\n}\n", ""]);
// Exports
module.exports = exports;
