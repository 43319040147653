<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style>
body{
  background: #f9f9f9;
}
#app{
  background: #f9f9f9;
}
.el-left{
  text-align: left;
}
.el-center{
  text-align: center;
}
.el-right{
  text-align: right;
}
.margin-btm{
  margin-bottom: 10px;
}
.font-span{
  color: #999;
}
.font-red{
  color: #f56c6c;
}
.font-orange{
  color: orange;
  color: #FF9800;
}
.font-blue{
  color: #409eff;
}
.font-green{
  color: #67c23a;
}
.font-blue{
  color: #409EFF;
}
.page-footer{
  text-align: right;
  margin: 10px 0;
}
/*table */
.custom-table tr td { border:1px solid #f0f0f0;color: rgba(0,0,0,.85);font-weight: normal;
    font-size: 14px;padding: 10px 24px; }
.custom-table { min-height: 25px; width: 100%; text-align: center; border-collapse: collapse;}
.tr-title{
  background-color: #fafafa;
  width: 150px;
}

.el-table th {
    color: rgba(0,0,0,.85)!important;
    font-weight: 500!important;
    text-align: left!important;
    background: #fafafa!important;
    border-bottom: 1px solid #e8e8e8!important;
    -webkit-transition: background .3s ease!important;
    transition: background .3s ease!important;
}
/*菜单选中*/
.el-menu-item.is-active {
    color: #fff !important;
    background: #409Eff !important; 
}
</style>