<template>
  <div style="height:100%">
    <div class="logo-headers">
      <!-- <img :src="logoImg" class="logo-img" /> -->
      <div class="logo-img" style="color: #fff;font-size: 24px;font-weight: bold;">企服通签约平台</div>
      <div class="right-menu">
          <el-dropdown 
          v-if="userType === '1' || userType === '2'"
          split-button 
          type="primary" 
          style="margin-right:10px" 
          size="mini"
          trigger="click" 
          @click.native="getOptions()"
          @command="handleCanmanyClick">
            {{accountName}}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in options" :key="item.enterpriseId" :command="commandVO(item)">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button size="mini" icon="el-icon-key" @click="dialogPwdVisible=true">修改密码</el-button>
          <el-button size="mini" icon="el-icon-switch-button" @click.native="logout">退出</el-button>
       
       
      </div>
    </div>

    <div :class="classObj" class="app-wrapper">
      <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
      <sidebar class="nav-sidebar" />
      <div class="main-container">
        <div :class="{'fixed-header':fixedHeader}">
          <navbar />
        </div>
        <app-main />
      </div>
    </div>

     <el-dialog title="修改密码" :visible.sync="dialogPwdVisible" width="600px" :close-on-click-modal="false">
      <el-form :model="ruleForm" ref="ruleForm" label-width="80px" size="mini">
        <el-form-item label="当前密码" prop="pwd" :rules="[{required: true,message:'请输入当前密码',trigger:'blur'}]">
            <el-input v-model="ruleForm.pwd" placeholder="请输入当前密码" :type="passwordType1" :key="passwordType1" auto-complete="on" ref="password1"/>
            <span class="show-pwd" @click="showPwd(1)">
              <svg-icon :icon-class="passwordType1 === 'password' ? 'eye' : 'eye-open'" />
            </span>
        </el-form-item>
        <el-form-item label="新的密码" prop="newPwd" :rules="[{required: true,validator: validatorNewPwd,trigger: 'blur'}]">
            <el-input :type="passwordType2" :key="passwordType2" auto-complete="on" ref="password2" v-model="ruleForm.newPwd" placeholder="新密码格式：必须包含字母大小写和数字。且密码长度最少8位以上" />
            <span class="show-pwd" @click="showPwd(2)">
              <svg-icon :icon-class="passwordType2 === 'password' ? 'eye' : 'eye-open'" />
            </span>
        </el-form-item>
        <el-form-item label="确认密码" prop="configPwd" :rules="[{required: true,validator: handleConfirmPass,trigger: 'blur'}]">
            <el-input :type="passwordType3" :key="passwordType3" auto-complete="on" ref="password3" v-model="ruleForm.configPwd" placeholder="确认密码必须和新密码一致"/>
            <span class="show-pwd" @click="showPwd(3)">
              <svg-icon :icon-class="passwordType3 === 'password' ? 'eye' : 'eye-open'" />
            </span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogPwdVisible=false" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('ruleForm')" size="mini">提 交</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import logo_img from "@/assets/logo2.png"
import { loginMD5 } from '@/utils/index'
import { editPwd } from '@/api/common_api'
import store from '@/store'
export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  data(){
    return {
      logoImg: logo_img,
      dialogPwdVisible:false,
      ruleForm: {
        configPwd:'',
        pwd:'',
        newPwd:''
      },
      passwordType1: "password",
      passwordType2: "password",
      passwordType3: "password",
      options:[],
      accountName:'请选择企业名称'
    }
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    userType() {
      return sessionStorage.getItem('userType')
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    async getOptions(){
      this.options = await store.dispatch('user/getUserIdCanmanyList',sessionStorage.getItem('id'));
    },
    async handleCanmanyClick(command){
      this.accountName = command.name;
      await store.dispatch('user/setUserType',command.enterpriseId);
    },
    commandVO(item){
      return {
        enterpriseId:item.enterpriseId,
        name:item.name
      }
    },
    logout() {
      this.$confirm("确认退出", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store.dispatch('user/logout');
        this.$router.push("/login");
      }).catch(() => {});
    },
    showPwd(types) {
      if(types === 1){
        if (this.passwordType1 === "password") {
          this.passwordType1 = "";
        } else {
          this.passwordType1 = "password";
        }
        this.$nextTick(() => {
          this.$refs.password1.focus();
        });
      }else if(types === 2){
        if (this.passwordType2 === "password") {
          this.passwordType2 = "";
        } else {
          this.passwordType2 = "password";
        }
        this.$nextTick(() => {
          this.$refs.password2.focus();
        });
      }else if(types === 3){
        if (this.passwordType3 === "password") {
          this.passwordType3 = "";
        } else {
          this.passwordType3 = "password";
        }
        this.$nextTick(() => {
          this.$refs.password3.focus();
        });
      }
      
    },
    validatorNewPwd(rule, value, callback){
      if (!value) {
         callback(new Error("请输入新密码"));
      } else {
          var pwdblank = /^\S*$/;
          var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
          var regUpper = /[A-Z]/;
          var regLower = /[a-z]/;
          var regStr = /[0-9]/;
          var complex = 0;
          if (regLower.test(value)) {
              ++complex;
          }
          if (regUpper.test(value)) {
              ++complex;
          }
          if (regStr.test(value)) {
              ++complex;
          }
          if(!pwdblank.test(value)){
           callback(new Error('新密码不能有空格！'))
          }else if(pattern.test(value)){
           callback(new Error('新密码不能有非法字符！'))
          }else if (complex < 3 || value.length < 8) {
           callback(new Error('密码格式不正确、必须包含字母大小写和数字。且密码长度最少8位以上！'))
          }
          callback();
      }
    },
    handleConfirmPass(rule,value, callback){
        if (!value) {
            callback(new Error("请输入确认密码"));
        }
        if (this.ruleForm.newPwd && this.ruleForm.newPwd !== value) {
            callback('新密码和确认密码不一致！')
        }
        callback()
    },
   handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
            let params = {
                pwd:loginMD5(this.ruleForm.pwd),
                newPwd:loginMD5(this.ruleForm.newPwd)
            }
            let req = await editPwd(params);
            if(req.code ===200){
                this.$message.success("修改成功！");
                this.$refs['ruleForm'].resetFields();
                this.dialogPwdVisible = false;
            }
        } else {
            return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";
  .logo-img{
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .nav-sidebar{
    -webkit-transition: width 0.28s;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    transition: width 0.28s;
    width: 210px !important;
  }
  #app .hideSidebar .nav-sidebar {
    width: 64px !important;
  }
  .app-wrapper {
    @include clearfix;
    position: relative;
    min-height: calc(100% - 60px);
    width: 100%;
    display: flex;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }


  .logo-headers{
    height: 60px;
    padding: 0 30px 0 30px;
    background-color: #1590fd;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    display: flex;
  }
  .right-menu {
    flex: 1;
    text-align: right;
    padding-top:15px;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: #889aa4;
    cursor: pointer;
    user-select: none;
    top: 50%;
    transform: translate(0, -50%);
  }

  
</style>
