import request from '@/utils/request'

/*消息 */
export function getNewsList(data) {
    return request({
      url: '/api/msg/pageListMsgTemplate',
      method: 'get',
      params:data
    })
  }
  export function newsDel(data) {
    return request({
        url: '/api/msg/delete',
        method: 'get',
        params:data
    })
  }
  export function newsAdd(data) {
    return request({
        url: '/api/msg/add',
        method: 'post',
        data
    })
  }
  export function newsEdit(data) {
    return request({
        url: '/api/msg/update',
        method: 'post',
        data
    })
  }

/*短信 */
  export function getMsgList(data) {
    return request({
      url: '/api/sms/pageListSmsTemplate',
      method: 'get',
      params:data
    })
  }
  export function msgDel(data) {
    return request({
        url: '/api/sms/delete',
        method: 'get',
        params:data
    })
  }
  export function msgAdd(data) {
    return request({
        url: '/api/sms/add',
        method: 'post',
        data
    })
  }
  export function msgEdit(data) {
    return request({
        url: '/api/sms/update',
        method: 'post',
        data
    })
  }
  export function msgState(data) {
    return request({
        url: '/api/sms/ban',
        method: 'post',
        data
    })
  }