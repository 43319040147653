import request from '@/utils/request'

export function getTemplateList(data) {
  return request({
    url: '/api/template/pageListTemplate',
    method: 'post',
    data
  })
}
export function editTemplate(data) {
  return request({
    url: '/api/template/updateFileTemplate',
    method: 'post',
    data
  })
}
export function templateImport(data) {
  return request({
    url: '/api/template/uploadTemplate',
    method: 'post',
    data
  })
}
export function templateDetails(data) {
  return request({
    url: '/api/template/detail',
    method: 'get',
    params: data
  })
}

export function addTempType(data) {
  return request({
    url: '/api/tempType/add',
    method: 'post',
    data
  })
}
export function editTempType(data) {
  return request({
    url: '/api/tempType/update',
    method: 'post',
    data
  })
}
export function delTempType(data) {
  return request({
    url: '/api/tempType/delete',
    method: 'get',
    params: data
  })
}
export function getTempTypeList(data) {
  return request({
    url: '/api/tempType/pageListTemplateType',
    method: 'post',
    data
  })
}
export function getTemplateTypeList(data) {
  return request({
    url: '/api/tempType/templateTypeList',
    method: 'get',
    data
  })
}
export function templateDel(id) {
  return request({
    url: `/api/template/delete/${id}`,
    method: 'delete'
  })
}
