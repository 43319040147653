import { login,getContractTemp,getComanyList,getUserList,getBatchCodeList,getTempTypeList,getUserIdCanmanyList} from '@/api/common_api'
import { getTemplateList } from '@/api/template_api'
import { getSysConmanyList} from '@/api/sys_manany_api'
import { constantRoutes } from '@/router'
import store from '@/store'
import { STORAGE_TOKEN } from "@/config/common"
import { loginMD5 } from "@/utils/index"

/*
function hasPermission(roles, route) {
  if (roles && roles.length !==0) {
    return roles.some(role => role.route.includes(route.name))
  } else {
    return true
  }
}
export function filterAsyncRoutes(routes, roles) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      tmp.children = filterAsyncRoutes(tmp.children, roles)
      res.push(tmp)
    }
  })
  return res
}
*/
function hasPermission(route, roles) {
  let res = [];
  route.forEach(v=>{
    if(!roles.includes(v.key)){
      res.push(v);
    }
  })
  return res;
}

export function filterAsyncRoutes(routes, roles) {
  const res = []
  routes.forEach(route => {
    if(!roles.includes(route.key)){
      const tmp = {...route};
      tmp.children = hasPermission(route.children, roles);
      res.push(tmp)
    }
  })
  return res
}

const state = {
  user:{},
  sign: false,
  routes:[],
  btns:[],
  userId:''
}

const mutations = {
  RESET_STATE: () => {
    sessionStorage.removeItem(STORAGE_TOKEN);
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER: (state, data) => {
    state.user = data
  },
  SET_SIGN: (state, sign) => {
    state.sign = sign
  },
  SET_ROUTES: (state, routes) => {
    state.routes = routes
  },
  SET_BTNS: (state, btns) => {
    state.btns = btns
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
}

const actions = {

  login({ commit },userInfo) {
    const {username,password} = userInfo;

    return new Promise((resolve, reject) => {
      login({username:username.trim(),password:loginMD5(password)}).then(async(res) => {
        if(res.code ===200){
          sessionStorage.setItem(STORAGE_TOKEN,res.data['token']);
          sessionStorage.setItem('id',res.data.id);
          sessionStorage.setItem('userType',res.data.type);
          sessionStorage.setItem('userName',res.data.userName);
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  generateRoutes({ commit }) {
    return new Promise((resolve, reject) => {
      const userType = sessionStorage.getItem('userType');
       // 1超级  2管理员  3企业
       let accessedRoutes = [];
       if(userType === '1'){
         accessedRoutes = filterAsyncRoutes(constantRoutes,[]);
       }else if(userType==='2'){
         accessedRoutes = filterAsyncRoutes(constantRoutes,['6','7','8','9']);
       }else if(userType==='3'){
         accessedRoutes = filterAsyncRoutes(constantRoutes,['6','7','8','9']);
       }
      commit('SET_ROUTES', accessedRoutes);
      commit('SET_SIGN', true);
      resolve(accessedRoutes);
    })
  },

  setUserType({ commit },userId) {
    return new Promise((resolve, reject) => {
      commit('SET_USER_ID', userId);
      resolve(userId);
    })
  },

  getTemp({ commit }) {
    return new Promise((resolve, reject) => {
      getContractTemp().then(res => {
        if(res.code ===200){
          resolve(res.data);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getComanyList({ commit }) {
    return new Promise((resolve, reject) => {
      getComanyList().then(res => {
        if(res.code ===200){
          resolve(res.data);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUserList({ commit }) {
    return new Promise((resolve, reject) => {
      getUserList().then(res => {
        if(res.code ===200){
          resolve(res.data);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getBatchCodeList({ commit }) {
    return new Promise((resolve, reject) => {
      getBatchCodeList().then(res => {
        if(res.code ===200){
          resolve(res.data);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getTempTypeList({ commit },data) {
    return new Promise((resolve, reject) => {
      getTempTypeList(data).then(res => {
        if(res.code ===200){
          resolve(res.data.list);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getTemplateList({ commit },data) {
    return new Promise((resolve, reject) => {
      getTemplateList(data).then(res => {
        if(res.code ===200){
          resolve(res.data.list);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  //绑定企业
  getSysConmanyList({ commit },data){
    return new Promise((resolve, reject) => {
      getSysConmanyList(data).then(res => {
        if(res.code ===200){
          resolve(res.data.list);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  //根据用户类型查询关联企业
  getUserIdCanmanyList({ commit },adminId){
    return new Promise((resolve, reject) => {
      getUserIdCanmanyList(adminId).then(res => {
        if(res.code ===200){
          resolve(res.data);
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  resetRoutes({ commit }, data) {
    commit('SET_SIGN', data)
  },

  logout({ commit }) {
    return new Promise(async(resolve, reject) => {
        await store.dispatch('user/resetRoutes', false)
        commit('RESET_STATE')
        resolve()
    })
  },

  resetToken({ commit }) {
    return new Promise(async resolve => {
      await store.dispatch('user/resetRoutes', false)
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

