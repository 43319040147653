<template>
  <div>

    <el-dialog title="模版类别列表" :visible.sync="temp.tempType.visible" :before-close="closeTempType"
      :close-on-click-modal="false" width="50%">

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span></span>
          <el-button type="primary" size="mini" style="float:right" icon="el-icon-plus"
            @click="addTemp('tempTypeInner')">新增类别</el-button>
        </div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="name" label="类别名称"></el-table-column>
          <el-table-column prop="createTime" label="创建日期"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row,'tempTypeInner')">编辑</el-button>
              <el-button type="text" size="mini" @click="del(scope.row,'tempTypeInner')">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-pagination class="page-footer" background layout="total,prev,pager,next,sizes,jumper" :total="total"
            :page-sizes="[10,20,30,40,50,100]" @current-change="handlePageChange" @size-change="handleSizeChange" />
        </el-row>
      </el-card>

      <el-dialog width="30%" title="新增类别" :visible.sync="temp.tempTypeInner.visible" append-to-body
        :close-on-click-modal="false">
        <el-form :model="tempTypeForm" ref="ruleForm" size="mini">
          <el-form-item label="类别名称" prop="name" :rules="[{required:true, message: '请输入类别名称', trigger: 'bulr'}]">
            <el-input v-model="tempTypeForm.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="temp.tempTypeInner.visible = false">取 消</el-button>
          <el-button type="primary" @click="tempTypeSubmit('ruleForm')">提交</el-button>
        </div>
      </el-dialog>

    </el-dialog>

    <el-dialog title="新增模版" :visible.sync="temp.temp.visible" :close-on-click-modal="false" width="30%">
      <el-form :model="tempForm" ref="tempForm" size="mini">
        <el-form-item label="模版名称" prop="templateName" :rules="[{required:true, message: '请输入模版名称', trigger: 'bulr'}]">
          <el-input v-model="tempForm.templateName"></el-input>
        </el-form-item>
        <el-form-item label="模版类别（支持模糊搜索）" prop="templateTypeId"
          :rules="[{required:true, message: '请选择模版类别', trigger: 'change'}]">
          <el-select v-model="tempForm.templateTypeId" filterable @click.native="typeChange" style="width:100%">
            <el-option v-for="item in tempTypeData" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务公司" prop="companyId" :rules="[{required:true, message: '请选择业务公司', trigger: 'change'}]">
          <el-select v-model="tempForm.companyId" filterable clearable @click.native="companyIdChange" @change="companyChange" style="width: 100%;">
            <el-option v-for="item in comanyList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模版（格式doc）" prop="file" :rules="[{required:true, message: '请上传模版', trigger: 'change'}]">
          <el-upload class="upload-demo" v-model="tempForm.file" :auto-upload="false"
            :file-list="fileList" ref="uploadPdf" action="#" :on-change="onChangeFile" :before-remove="beforeRemove">
            <el-button slot="trigger" size="mini" type="primary">选取文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="temp.temp.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="tempSubmit('tempForm')">确 定</el-button>
      </div>
    </el-dialog>

    <div class="temp-mian">
      <div class="temp-tree">
        <div class="classify-mian">
          <el-select v-model="companyId" filterable clearable @click.native="companyIdChange" @change="companyChange"
            style="width:100%;margin: 10px 0;" placeholder="选择业务公司">
            <el-option v-for="item in comanyList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </div>
        <div class="classify-mian">
          <div class="classify-title">分类信息</div>
          <div v-if="userType ==='3'">
            <el-button type="primary" size="mini" @click="addTemp('tempType')">新增类别</el-button>
            <el-button type="primary" size="mini" @click="addTemp('temp')">新增模版</el-button>
          </div>
        </div>
        <el-tree :data="tempTypeTab" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <div class="temp-inner">
        <ul class="pdf-uis">
          <li v-for="(item,index) in contractTemp" :key="index" class="pdf-lis">
            <div class="pdf-inner">
              <h1 class="pdf-title">{{item.name}}</h1>
              <div class="pdf-coer">
                <img :src="pdfImg">
              </div>
              <div class="pdf-footer" v-if="userType ==='3'">
                <el-button type="text" style="float:left" size="mini" @click="setPdfTemp(item)">
                  配置占位符（{{item.placeholderStatus === 0 ? '未配置':'已配置'}}）</el-button>
                <el-button type="text" style="float:right" size="mini" @click="delPdfStatus(item)">
                  删除
                </el-button>
                <el-button type="text" style="float:right" size="mini" @click="setPdfStatus(item)">
                  {{item.status === 1 ? '正常':'禁用'}}
                </el-button>
              </div>
            </div>
          </li>
        </ul>
        <img v-show="flag" :src="emtps" style="width:280px;margin: 120px auto;display: block;">
      </div>
    </div>

  </div>
</template>

<script>
  import {
    getTemplateList,
    editTemplate,
    templateImport,
    addTempType,
    editTempType,
    delTempType,
    getTempTypeList,
    getTemplateTypeList,
    templateDel
  } from '@/api/template_api'
  import store from '@/store'
  import noData from "@/assets/no_data.png"
  import pdfImg from "@/assets/pdf_file.png"
  export default {
    name: "templateType",
    data() {
      return {
        pdfImg: pdfImg,
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id'
        },
        temp: {
          width: '600px',
          temp: {
            title: '新增模版',
            visible: false
          },
          tempType: {
            title: '新增类别',
            visible: false
          },
          tempTypeInner: {
            title: '新增类别名称',
            visible: false
          }
        },
        tempTypeForm: {
          name: '',
          id: ''
        },
        tempTypeParams: {
          pageNum: 1,
          pageSize: 10
        },
        tableData: [],
        total: 0,
        activeName: "",
        tempForm: {
          templateName: '',
          templateTypeId: '',
          companyId: '',
          file: ''
        },
        tempTypeData: [],
        fileList: [],
        tempTypeTab: [],
        contractTemp: [],
        flag: false,
        emtps: noData,
        tempType: "",

        comanyList: [], // 业务公司列表
        companyId: "", // 选择业务公司
      };
    },
    computed: {
      setUserType() {
        return this.$store.getters.userId
      },
      userType() {
        return sessionStorage.getItem('userType')
      }
    },
    watch: {
      setUserType: {
        handler(newValue) {
          this.loadTempType();
        }
      }
    },
    mounted() {
      this.loadTempType();
    },
    methods: {
      // 业务公司初始化
      async companyIdChange() {
        this.comanyList = [];
        this.comanyList = await store.dispatch('user/getComanyList');
      },
      // 业务公司选择
      companyChange() {
        this.loadTempType();
      },
      handleNodeClick(row) {
        this.tempType = row.id;
        // this.loadTempList();
        this.tempTypeTab.forEach(item=> {
          if (item.id == this.tempType) {
            this.contractTemp = item.templateVOList
          }
        })
      },
      async loadTypeList(flag) {
        if (flag) {
          this.tempTypeParams.pageNum = 1;
        }
        const userType = sessionStorage.getItem('userType');
        if (userType === '1') {
          if (this.$store.getters.userId) {
            this.tempTypeParams.enterprises = [this.$store.getters.userId];
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            this.tempTypeParams.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        } else if (userType === '2') {
          if (this.$store.getters.userId) {
            this.tempTypeParams.enterprises = [this.$store.getters.userId]
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            this.tempTypeParams.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        }
        let req = await getTempTypeList(this.tempTypeParams);
        if (req.code === 200) {
          this.tableData = req.data.list;
          this.total = req.data.total;
        }
      },
      handlePageChange(page) {
        this.tempTypeParams.pageNum = page;
        this.loadTypeList(false);
      },
      handleSizeChange(page) {
        this.tempTypeParams.pageSize = page;
        this.loadTypeList(false);
      },
      addTemp(type) {
        if (type === 'tempType') {
          this.loadTypeList(true);
        } else if (type === 'tempTypeInner') {
          this.tempTypeForm.name = '';
          this.tempTypeForm.id = '';
        } else if (type === 'temp') {
          this.tempForm = {
            templateName: '',
            templateTypeId: '',
            file: ''
          }
          this.fileList = [];
        }
        this.temp[type].visible = true;
      },
      edit(row, type) {
        this.temp[type].visible = true;
        this.tempTypeForm.name = row.name;
        this.tempTypeForm.id = row.id;
      },
      del(row, type) {
        this.$confirm('是否删除？', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          let params = {
            id: row.id
          }
          let req = await delTempType(params);
          if (req.code === 200) {
            this.$message.success("操作成功");
            this.temp[type].visible = false;
            this.loadTypeList(false);
          }
        }).catch((error) => {});
      },
      tempTypeSubmit(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let params = {}
            if (this.tempTypeForm.id) {
              params.id = this.tempTypeForm.id;
            }
            params.name = this.tempTypeForm.name;
            let req = this.tempTypeForm.id === '' ? await addTempType(params) : await editTempType(params);
            if (req.code === 200) {
              this.$message.success('操作成功');
              this.temp.tempTypeInner.visible = false;
              this.loadTypeList(true);
            }
          } else {
            return false;
          }
        });
      },
      closeTempType() {
        this.temp.tempType.visible = false;
        this.loadTempType();
      },
      async loadTempType() {
        let params = {};
        const userType = sessionStorage.getItem('userType');
        if (userType === '1') {
          if (this.$store.getters.userId) {
            params.enterprises = [this.$store.getters.userId];
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            params.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        } else if (userType === '2') {
          if (this.$store.getters.userId) {
            params.enterprises = [this.$store.getters.userId];
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            params.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        }
        params.companyId = this.companyId;
        let arr = await store.dispatch('user/getTempTypeList', params);
        // arr.forEach(v => {
        //   v.id = String(v.id);
        // })
        this.tempTypeTab = arr;
        if (this.tempTypeTab != undefined && this.tempTypeTab.length !== 0) {
          this.activeName = String(this.tempTypeTab[0].id);
          this.tempType = this.tempTypeTab[0].id;
          this.contractTemp = this.tempTypeTab[0].templateVOList;
          this.flag = false;
          // this.loadTempList();
        } else {
          this.contractTemp = [];
          this.flag = true;
        }
      },
      async loadTempList() {
        let params = {};
        params.type = this.tempType;
        const userType = sessionStorage.getItem('userType');
        if (userType === '1') {
          if (this.$store.getters.userId) {
            params.enterprises = [this.$store.getters.userId];
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            params.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        } else if (userType === '2') {
          if (this.$store.getters.userId) {
            params.enterprises = [this.$store.getters.userId]
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            params.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        }
        let req = await getTemplateList(params);
        if (req.code === 200) {
          if (req.data.list && req.data.list.length !== 0) {
            this.contractTemp = req.data.list;
            this.flag = false;
          } else {
            this.contractTemp = [];
            this.flag = true;
          }
        }
      },
      handleClick(tab, event) {
        this.tempType = tab.name;
        this.loadTempList();
      },
      tempSubmit(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let formData = new FormData();
            formData.append('companyId', this.tempForm.companyId);
            formData.append('file', this.tempForm.file);
            formData.append('templateTypeId', this.tempForm.templateTypeId);
            formData.append('templateName', this.tempForm.templateName);
            let req = await templateImport(formData)
            if (req.code === 200) {
              this.$message.success('操作成功');
              this.temp.temp.visible = false;
              this.loadTempType();
              console.log(this.tempTypeTab);
              this.activeName = String(this.tempTypeTab[0].id);
              this.tempType = this.tempTypeTab[0].id;
            }
          } else {
            return false;
          }
        });
      },
      async typeChange() {
        let params = {};
        const userType = sessionStorage.getItem('userType');
        // if (userType === '1') {
        //   if (this.$store.getters.userId) {
        //     params.enterprises = [this.$store.getters.userId];
        //   } else {
        //     const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
        //     params.enterprises = canmanyList.map(v => v.enterpriseId);
        //   }
        // } else if (userType === '2') {
        //   if (this.$store.getters.userId) {
        //     params.enterprises = [this.$store.getters.userId]
        //   } else {
        //     const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
        //     params.enterprises = canmanyList.map(v => v.enterpriseId);
        //   }
        // }
        // this.tempTypeData = await store.dispatch('user/getTempTypeList', params);
        let req = await getTemplateTypeList(params);
        if (req.code == 200) {
          this.tempTypeData = req.data
        }
      },
      async onChangeFile(file, fileList) {
        this.tempForm.file = file.raw;
      },
      beforeRemove(file, fileList) {
        this.tempForm.file = '';
        return this.$message.error(`${file.name}移除`)
      },
      setPdfTemp(row) {
        this.$router.push({
          path: "/template/set",
          name: "TemplateSet",
          query: {
            id: row.id
          }
        });
      },
      delPdfStatus(row) {
        this.$confirm('是否删除？', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          let req = await templateDel(row.id);
          if (req.code === 200) {
            this.$message.success("删除成功");
            this.loadTempType();
          }
        }).catch((error) => {});
      },
      setPdfStatus(row) {
        let _status = row.status === 1 ? 0 : 1;
        let _stateText = row.status === 1 ? '确认禁用模版' : '确认启用模版';
        this.$confirm(_stateText, "友情提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          let params = {
            id: row.id,
            status: _status
          };
          let req = await editTemplate(params);
          if (req.code === 200) {
            this.$message.success("操作成功");
            this.loadTempType();
          }
        }).catch(() => {});
      }
    }
  };
</script>
<style>
  .imgs {
    height: 70px;
    width: 70px;
    margin: 4px;
    cursor: pointer;
  }

  .el-drawer {
    overflow: scroll;
  }

  .pdf-uis {
    overflow: hidden;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .pdf-lis {
    float: left;
    width: 20%;
  }

  .pdf-inner {
    margin: 30px;
  }

  .pdf-coer {
    overflow: hidden;
  }

  .pdf-coer img {
    display: block;
    width: 100%;
  }

  .pdf-footer {
    text-align: center;
    margin-top: 10px;
    overflow: hidden;
  }

  .pdf-title {
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 0;
  }

  .temp-mian {
    display: flex;
  }

  .temp-tree {
    width: 320px;
    margin-right: 10px;
    border: 1px solid rgb(235, 238, 244);
  }

  .temp-inner {
    flex: 1;
    border: 1px solid rgb(235, 238, 244);
  }

  .classify-mian {
    background: rgb(250, 250, 250);
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(235, 238, 244);
    padding: 0px 10px;
    line-height: 40px;
    display: flex;
  }

  .classify-title {
    flex: 1;
  }
</style>
