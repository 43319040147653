<template>
    <div>
       <el-form :model="params" size="mini" :inline="true">
          <el-form-item label="姓名">
              <el-input v-model="params.queryName"></el-input>
          </el-form-item>
          <el-form-item label="昵称">
              <el-input v-model="params.queryNickName"></el-input>
          </el-form-item>
        <el-button type="primary" size="mini" icon="el-icon-search" @click="loadList(true)">查询</el-button>
        <el-button type="primary" size="mini" icon="el-icon-setting"  @click="restForm">重置</el-button>
        <el-button type="danger" size="mini" v-if="userType ==='3'" icon="el-icon-upload" @click="exportUser">导出</el-button>
       </el-form>
       <el-alert v-if="tis" :title="tis" type="success" show-icon :closable="false"/>
        <el-table 
          :data="tableData" 
          :row-key="getRowKeys"
          @selection-change="handleSelectionChange"  
          stripe
          ref="table">
          <el-table-column type="selection" align="center" width="45" v-if="userType ==='3'" :reserve-selection="true"></el-table-column>
            <el-table-column prop="nickName" label="昵称"></el-table-column>
            <el-table-column prop="avatarUrl" label="头像">
              <template slot-scope="scope">
                  <viewer>
                      <img :src="scope.row.avatarUrl" alt="无图片" style="height: 50px; width: 50px;cursor: pointer;" />
                  </viewer>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="真实姓名"></el-table-column>
            <el-table-column prop="stateName" label="状态"></el-table-column>
            <el-table-column prop="createTime" label="创建日期"></el-table-column>
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="toogleExpand(scope.row)">查看详情{{scope.row.name === null?'':'（已登记）'}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-pagination
            class="page-footer"
            :key="compoentsKey"
            background
            layout="total,prev,pager,next,sizes,jumper"
            :total="total"
            :page-sizes="[10,20,30,40,50,100]"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            />
        </el-row>

        <el-drawer title="微信用户明细" :visible.sync="drawerVisible" direction="btt" size="50%">
                <el-divider content-position="left">基础信息</el-divider>
                <table class="custom-table">
                  <tr>
                      <td class="tr-title">姓名</td><td> {{details.name}}</td>
                      <td class="tr-title">手机号</td><td> {{details.mobile}}</td>
                      <td class="tr-title">性别</td><td>{{details.gender}}</td>
                  </tr>
                  <tr>
                      <td class="tr-title">银行卡号</td><td>{{details.cardNo}}</td>
                      <td class="tr-title">身份证号</td><td>{{details.idCard}}</td>
                      <td class="tr-title">婚姻状况</td><td> {{details.marital}}</td>
                  </tr>
                  <tr>
                      <td class="tr-title">城市</td><td> {{details.area}}</td>
                      <td class="tr-title">民族</td><td>{{details.nation}}</td>
                      <td class="tr-title">单位名称</td><td>{{details.company}}</td>
                  </tr>
                   <tr>
                      <td class="tr-title">户口性质</td><td> {{details.nature}}</td>
                      <td class="tr-title">是否服役</td><td>{{details.military}}</td>
                      <td class="tr-title">电子邮箱</td><td>{{details.email}}</td>
                  </tr>
                  <tr>
                      <td class="tr-title">毕业院校</td><td> {{details.graduateSchool}}</td>
                      <td class="tr-title">学历</td><td>{{details.diploma}}</td>
                      <td class="tr-title">毕业时间</td><td>{{details.graduateTime}}</td>
                  </tr>
                  <tr>
                      <td class="tr-title">户籍地址</td><td> {{details.residenceAddress}}</td>
                      <td class="tr-title">服务部门(区域)</td><td> {{details.section}}</td>
                      <td class="tr-title">家庭住址</td><td>{{details.address}}</td>
                  </tr>
                  <tr>
                     <td class="tr-title">身份证正面</td><td> 
                        <viewer>
                            <img :src="details.idCardImgFront" alt="无图片" class="imgs" />
                          </viewer>
                      </td>
                      <td class="tr-title">身份证反面</td><td> 
                        <viewer>
                            <img :src="details.idCardImgVerso" alt="无图片" class="imgs" />
                          </viewer>
                      </td>
                      <td class="tr-title">银行卡正面</td><td>
                        <viewer>
                            <img :src="details.cardImgFront" alt="无图片" class="imgs" />
                          </viewer>
                      </td>
                  </tr>
                  <tr>
                     <td class="tr-title">银行卡反面</td><td> 
                        <viewer>
                            <img :src="details.cardImgVerso" alt="无图片" class="imgs" />
                          </viewer>
                      </td>
                      <td class="tr-title">登记照</td><td> 
                        <viewer>
                            <img :src="details.picture" alt="无图片" class="imgs" />
                          </viewer>
                      </td>
                      <td class="tr-title">体检报告照片</td><td>
                        <viewer>
                            <img :src="details.imgs" alt="无图片" class="imgs" />
                          </viewer>
                      </td>
                  </tr>
                   <tr>
                      <td class="tr-title">特种作业照片</td>
                      <td> 
                          <viewer :images="details.imgList">
                              <img v-for="(src,_index) in details.imgList" :key="_index" :src="src" alt="无图片" class="imgs" />
                            </viewer>
                        </td>
                      <td class="tr-title">专业操作视频</td>
                      <td> 
                        <video v-show="details.video" :src="details.video" width="120" height="80" controls="controls">
                          您的浏览器不支持 video 标签，请更换主流最新版的游览器（比如谷歌游览器、QQ游览器）。
                          </video>
                      </td>
                  </tr>
                </table>
                <el-divider content-position="left">紧急联系人</el-divider>
                <el-table :data="details.contact" border stripe size="mini">
                  <el-table-column align="center" type="index" width="55" label="序号"></el-table-column>
                  <el-table-column prop="type" label="类型"></el-table-column>
                  <el-table-column prop="contact" label="联系方式"></el-table-column>
                  <el-table-column prop="name" label="姓名"></el-table-column>
                </el-table>
        </el-drawer>

    </div>
</template>

<script>
import { getUserList,userDetails,userExport} from '@/api/user_api'
import { xlsx } from "@/utils/xlsx"
import store from '@/store'
export default {
    name: "user",
    data() {
        return {
          tableData:[],
          total:0,
          params:{
            queryName:'',
            queryNickName:'',
            pageNum:1,
            pageSize:10
          },
          drawerVisible:false,
          details:{},
          selectItem:[],
          tis:"",
          compoentsKey:1
        };
    },
    computed: {
        setUserType(){
            return this.$store.getters.userId
        },
        userType(){
            return sessionStorage.getItem('userType')
        }
    },
    watch: {
        setUserType: {
            handler(newValue){  
               this.loadList(true);
            }
        }

    },
    mounted(){
       this.loadList(true);
    },
    methods: {
        async loadList(flag){
          if(flag){
            this.params.pageNum = 1;
            this.compoentsKey +=1;
          }
          const userType = sessionStorage.getItem('userType');
          if(userType === '1'){
            if(this.$store.getters.userId){
              this.params.enterprises = [this.$store.getters.userId];
            }else{
             const canmanyList = await store.dispatch('user/getUserIdCanmanyList',sessionStorage.getItem('id'));
             this.params.enterprises = canmanyList.map(v=>v.enterpriseId);
            }
          }else if(userType === '2'){
            if(this.$store.getters.userId){
              this.params.enterprises = [this.$store.getters.userId]
            }else{
             const canmanyList = await store.dispatch('user/getUserIdCanmanyList',sessionStorage.getItem('id'));
             this.params.enterprises = canmanyList.map(v=>v.enterpriseId);
            }
          }
          let req = await getUserList(this.params);
          if(req.code === 200){
            this.tableData = req.data.list;
            this.total = req.data.total;
          } 
        },
        handlePageChange(page) {
           this.params.pageNum = page;
            this.loadList(false);
        },
        handleSizeChange(page) {
            this.params.pageSize = page;
            this.loadList(false);
        },
        async toogleExpand(row) {
          let req = await userDetails(row.id);
          if(req.code === 200){
            if(req.data.other !== null){
              req.data.other.forEach(v=>{
                if(v.type == 1){
                  req.data.imgList = v.content.split(',');
                }else if(v.type == 2){
                  req.data.video = v.content;
                }else if(v.type == 3){
                  req.data.imgs = v.content;
                }
              })
            }
            this.details = req.data;
          }
          this.drawerVisible = true;
        },
        getRowKeys(row) {
          return row.id
        },
        handleSelectionChange(val) {
          let ids = [];
          val.forEach(item =>{ 
              ids.push(item.id)
          });
          this.selectItem = ids;
          this.tis="已选择 "+val.length+" 项";
          if(val.length==0){
            this.tis="";
            this.selectItem = [];
          }
        },
        async exportUser(){
          if(this.selectItem.length === 0){
              return this.$message.error("请勾选要导出的数据！");
          }
          let params = {
            id:this.selectItem.join(',')
          }
          let req = await userExport(params);
          if(req.code === 200){
            let fields = {
              title0: "编号",
              title1: "姓名",
              title2: "昵称",
              title3: "手机号",
              title4: "性别",
              title5: "银行卡号",
              title6: "身份证号",
              title7: "婚姻状态",
              title8: "城市",
              title9: "民族",
              title10: "单位",
              /*title11:"户口性质",
              title12:"是否服役",
              title13:"电子邮箱",
              title14:"学历",
              title15:"毕业时间",
              title16:"毕业院校",
              title17:"户籍地址",*/
              title18: "服务部门",
              title19: "家庭住址"
            };
            let arry = [];
            let i = 0;
            req.data.forEach(ele => {
                i++;
                arry.push({
                    title0: i,
                    title1: ele.name,
                    title2: ele.nickName,
                    title3: ele.mobile,
                    title4: ele.gender,
                    title5: ele.cardNo,
                    title6: ele.idCard,
                    title7: ele.marital,
                    title8: ele.area,
                    title9: ele.nation,
                    title10: ele.company,
                  /*title11:ele.nature,
                    title12:ele.military,
                    title13:ele.email,
                    title14:ele.diploma,
                    title15:ele.graduateTime,
                    title16:ele.graduateSchool,
                    title17:ele.residenceAddress,*/
                    title18: ele.section,
                    title19: ele.address,
                });
            });
            xlsx(arry, fields, "微信用户列表");
            this.clearSelection();
          }
        },
        clearSelection(){
          this.$refs.table.clearSelection();
          this.selectItem = [];
          this.tis = "";
        },
        restForm(){
          this.params.queryNickName = '';
          this.params.queryName = '';
          this.loadList(true);
        }
    }
};
</script>
<style>
 .imgs{
   height: 70px; width: 70px;margin:4px;cursor: pointer;
 }
.el-drawer {
    overflow: scroll;
}
</style>
