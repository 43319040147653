import Vue from 'vue'
import 'normalize.css/normalize.css' 
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' 
import '@/styles/index.scss'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import App from './App'
import store from './store'
import router from './router'
import '@/icons' 
import '@/permission' 
Vue.use(ElementUI, { locale,zIndex: 1000 })
Vue.use(Viewer)
Vue.config.productionTip = false
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
