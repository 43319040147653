import axios from 'axios'
import { MessageBox, Message,Loading } from 'element-ui'
import router from '@/router'
import { STORAGE_TOKEN } from "@/config/common"

let loading;

window.tipLock = false;



function baseURL(){
  let url = "";
  if(process.env.NODE_ENV === "development"){
    url = process.env.VUE_APP_DEV_API;
  }else if(process.env.NODE_ENV === "production"){
    url = process.env.VUE_APP_PROD_API;
  }else if(process.env.NODE_ENV === "staging"){
    url = process.env.VUE_APP_STAG_API;
  }
  return url;
}

let whiteList = [true];//远程时时请求 屏蔽动画

const service = axios.create({
  baseURL: baseURL(),
  timeout: 50000
})
service.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem(STORAGE_TOKEN);
    if(token){
      config.headers['X-Token'] = token;
    }
    if (whiteList.indexOf(config.isTrue) === -1) {
      startLoading();
    }
    return config
  },
  error => {

    return Promise.reject(error)
  }
)


service.interceptors.response.use(response => {
  // console.log(response.headers);
    const res = response.data;
    endLoading();
    if(res.code === 200){
      return res;
    } else if(res.code === 1){
      Message({ message: res.msg, type: 'error', duration: 3000 });
      return Promise;
    } else if (response.headers['content-type'] == "application/vnd.ms-excel;charset=utf-8") {
      return res;
    } else {
      MessageBox.confirm(res.msg, '温馨提示', {
        confirmButtonText: "登录",
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
          router.push({ path: '/login' });
      }).catch(()=>{})
      return Promise;
    }
  },(error)=> {
    endLoading();
    Message({ message: '系统升级，请联系管理员', type: 'error', duration: 3000 });
    router.push({ path: '/login' });
    return Promise;
  }
)

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {
  //接口不加载动画的处理
  if(loading === undefined)return;
  loading.close()
}

export default service
