<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix flexs" :class="goTopShow === true ? 'pages-scrolltop' : ''">
                 <div class="pages">
                    <el-button @click="changePdfPage(0)" type="primary" size="mini" :disabled="currentPage==1">上一页</el-button>
                    {{currentPage}} / {{pageCount}}
                    <el-button @click="changePdfPage(1)" type="primary" size="mini" :disabled="currentPage==pageCount">下一页</el-button>
                </div>
                <el-button size="mini" @click="goPath" type="primary">返回列表</el-button>
            </div>
            <pdf
                :src="pdfUrl" 
                :page="currentPage" 
                @num-pages="pageCount=$event" 
                @page-loaded="currentPage=$event"> 
            </pdf>
        </el-card>
         
    </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
    name: "sign",
    components:{
        pdf,
    },
    data() {
        return {
            pdfUrl:'http://file.gp58.com/file/2018-11-14/111405.pdf',
            currentPage: 1, 
            pageCount: 0,
            numPages:0,
            goTopShow: false,
        };
    },
    mounted(){
        this.getPdf();
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        getPdf(){
            this.pdfUrl = pdf.createLoadingTask(this.pdfUrl)
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop > 120) {
                this.goTopShow = true
            }else{
                this.goTopShow = false;
            }
        },
        changePdfPage (val) {
            if (val === 0) {
                this.currentPage--
            }else{
                this.currentPage++
            }
        },
        goPath(){
            this.$router.push({path:"/contract/list",name:"ContractList"});
        }
    }
};
</script>
<style>
.flexs{
    display:flex;
}
.pages{
    flex: 1;
}
.pages-scrolltop{
    position:fixed;
    top:0;
    padding: 20px 0;
    background: #fff;
    z-index: 999;
    width: calc(100% - 310px);
}
</style>
