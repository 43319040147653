import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' 
import 'nprogress/nprogress.css' 
import getPageTitle from '@/utils/get-page-title'
import { STORAGE_TOKEN } from "@/config/common"

NProgress.configure({ showSpinner: false }) 


let whiteList = ['/login'] 

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  document.title = getPageTitle(to.meta.title)
  let hasToken = sessionStorage.getItem(STORAGE_TOKEN);
  if (hasToken) {
    if (to.path === '/login') {
      NProgress.done()
      next()
    } else {
      let sign = store.getters.sign;
      if (sign) {
        next()
      } else {
        try {
          const accessRoutes = await store.dispatch('user/generateRoutes');
          router.addRoutes(accessRoutes);
          if(from.name==null){
            next(to)
          }else{
            next({ ...to, replace: true })
          }
        } catch (error) {
          await store.dispatch('user/resetToken')
          Message.error(error || '未获取权限，请联系维护人员')
          next(`/login`)
          NProgress.done()
        }
      }
    }

  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      Message.error('非法访问，请重新登陆授权！');
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
