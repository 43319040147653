var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _c("div", { staticClass: "cyan" }, [
        _c("img", { staticClass: "head-img", attrs: { src: _vm.headImg } }),
        _c("div", { staticClass: "cyan-inner" }, [
          _c("label", [_vm._v(_vm._s(_vm.userUame))])
        ])
      ]),
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-menu",
        {
          staticStyle: { height: "100%", overflow: "auto" },
          attrs: {
            "default-active": _vm.activeMenu,
            "default-openeds": _vm.openeds,
            collapse: _vm.isCollapse,
            "unique-opened": false,
            "collapse-transition": false,
            mode: "vertical"
          }
        },
        [
          _vm._l(_vm.permission_routes, function(item, index) {
            return [
              item.type === "0"
                ? [
                    _c(
                      "el-menu-item",
                      {
                        key: index,
                        attrs: { index: item.key },
                        on: {
                          click: function($event) {
                            return _vm.handleClick(item)
                          }
                        }
                      },
                      [
                        _c("item", {
                          attrs: {
                            icon: item.meta.icon,
                            title: item.meta.title
                          }
                        })
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "el-submenu",
                      { key: index, attrs: { index: item.key } },
                      [
                        _c(
                          "template",
                          { slot: "title" },
                          [
                            _c("item", {
                              attrs: {
                                icon: item.meta.icon,
                                title: item.meta.title
                              }
                            })
                          ],
                          1
                        ),
                        _vm._l(item.children, function(child, childIndex) {
                          return _c(
                            "el-menu-item",
                            {
                              key: childIndex,
                              attrs: { index: child.key },
                              on: {
                                click: function($event) {
                                  return _vm.handleClick(child)
                                }
                              }
                            },
                            [
                              _c("item", {
                                attrs: {
                                  icon: child.meta.icon,
                                  title: child.meta.title
                                }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }