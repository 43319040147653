import request from '@/utils/request'

export function initContractList(data) {
  return request({
    url: '/api/pact',
    method: 'post',
    data
  })
}
export function contractAdd(data) {
  return request({
    url: '/api/pact/save',
    method: 'post',
    data
  })
}
export function contractApply(data) {
  return request({
    url: 'api/pact',
    method: 'put',
    data
  })
}
export function contractDetails(id) {
  return request({
    url: `/api/pact/${id}`,
    method: 'get',
  })
}
export function contractDel(id) {
  return request({
    url: `/api/pact/${id}`,
    method: 'delete'
  })
}
export function contractNewDel(data) {
  return request({
    url: '/api/pact/update',
    method: 'delete',
    params: data
  })
}
export function contractMsg() {
  return request({
    url: '/api/sendExpirePactsMsg',
    method: 'get',
  })
}
export function queryByCompanyId(id) {
  return request({
    url: `/api/template/queryTemplateByCompanyId/${id}`,
    method: 'get',
  })
}
//上传文件
export function pactExcelImport(data) {
  return request({
    url: '/api/pact/excel/import',
    method: 'post',
    data
  })
}
// 邀约人员列表
export function userBatchList(data) {
  return request({
    url: `/api/queryTemplateUserByBatchCode`,
    method: 'post',
    data
  })
}
// 导入人员删除
export function deleteUser(id) {
  return request({
    url: `/api/deleteTempUser/${id}`,
    method: 'delete'
  })
}
// 确认邀请
export function invitedUser(code) {
  return request({
    url: `/api/invited/${code}`,
    method: 'put'
  })
}
// 下载签约人员
export function downTemplate(code) {
  return request({
    url: `/api/template/downTemplate/${code}`,
    method: 'get',
    responseType: 'blob'
  })
}
