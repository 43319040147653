<template>
  <div :class="{'has-logo':showLogo}">
    <div class="cyan">
      <img class="head-img" :src="headImg"/>
      <div class="cyan-inner">
        <label>{{userUame}}</label>
      </div>
    </div>
    <logo v-if="showLogo" :collapse="isCollapse" />
      <el-menu
        style="height: 100%;overflow: auto;"
        :default-active="activeMenu"
        :default-openeds="openeds"
        :collapse="isCollapse"
        :unique-opened="false"
        :collapse-transition="false"
        mode="vertical">
        <template v-for="(item, index) in permission_routes">

          <template v-if="item.type ==='0'">
            <el-menu-item :key="index" :index="item.key" @click="handleClick(item)">
            <item :icon="item.meta.icon" :title="item.meta.title" />
          </el-menu-item>
          </template>
          <template v-else>
            <el-submenu :key="index" :index="item.key">
              <template slot="title">
                <item :icon="item.meta.icon" :title="item.meta.title" />
              </template>
              <el-menu-item :key="childIndex" :index="child.key" @click="handleClick(child)" v-for="(child,childIndex) in item.children" >
                  <item :icon="child.meta.icon" :title="child.meta.title" />
              </el-menu-item>
            </el-submenu>
          </template>
        </template>
      </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import Item from './Item'
import avatar from "@/assets/avatar.jpeg"
import axios from 'axios'
export default {
  components: {  Logo,Item },
  data(){
    return {
      headImg:avatar,
      activeMenu: '1',
      high:{}
    }
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'permission_user',
      'sidebar'
    ]),
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    userUame() {
      return sessionStorage.getItem('userName')
    },
    openeds(){
      return ['2','3','4','5','6']
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  mounted() {
    //this.activeMenu = this.$route.path;
    this.permission_routes.find(item=>{
        item.children.filter(el=>{
          if(el.path === this.$route.path){
            this.activeMenu = el.key;
            
        }
      })
    })
    //this.gethidt();
  },
  methods:{
    /* gethidt(){
      axios.get('http://wthrcdn.etouch.cn/weather_mini?city=重庆').then(response => {
        var obj = response.data.data;
        var _city = obj.city;
        var _date = obj.forecast[0].date;
        var _type = obj.forecast[0].type;
        var _high = obj.forecast[0].high;
				var _low = obj.forecast[0].low;
        var params = {
          city:_city,
          date:_date,
          type:_type,
          high:_high,
          low:_low
        }
        this.high = params;
      })
    }, */
    handleClick(record) {
      if (this.$route.path === record.path) return;
      this.$router.push(record.path);
    }
  }
}
</script>
<style>
  .cyan{
    background: url(../../../assets/user-bg.jpeg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 15px 0 15px 15px;
    display: flex;
    color: #fff;
  }
  .cyan-inner{
    flex: 1;
    margin-left: 10px;
  }
  .cyan-inner label{
    display: block;
  }
  .cyan-inner p{
    margin-top: 10;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
  }
  .head-img{
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }
</style>
