import fs from 'file-saver'
import XLSX from 'xlsx'

export function xlsx(json, fields, filename = '.xlsx') {
    json.forEach(item => {
        for (let i in item) {
            if (fields.hasOwnProperty(i)) {
                item[fields[i]] = item[i];
            }
            delete item[i]; 
        }
    })
    let sheetName = filename
    let wb = XLSX.utils.book_new()  
    let ws = XLSX.utils.json_to_sheet(json, { header: Object.values(fields) }) 
    wb.SheetNames.push(sheetName)
    wb.Sheets[sheetName] = ws
    const defaultCellStyle = { font: { name: "Verdana", sz: 13, color: "FF00FF88" }, fill: { fgColor: { rgb: "FFFFAA00" } } };
    let wopts = { bookType: 'xlsx', bookSST: false, type: 'binary', cellStyles: true, defaultCellStyle: defaultCellStyle, showGridLines: false }  
    let wbout = XLSX.write(wb, wopts)
    let blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
    fs.saveAs(blob, filename + '.xlsx')
}

const s2ab = s => {
    var buf;
    if (typeof ArrayBuffer !== 'undefined') {
        buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
        return buf
    } else {
        buf = new Array(s.length);
        for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
}

export function importXlsx(array,text){
    let fields = {
        number:"编号",name:"客户姓名",phone:"客户电话",wechat:"微信号",sex:"性别",
        ageRange:"年龄",address:"客户地址",productName:"商品名称",packageName:"套餐名称",boxCount:"箱数",
        bottleCount:"购买瓶数",packageUnitPrice:"套餐单价",packageTotalPrice:"套餐总金额",
        packageIncreasePrice:"套餐加价金额",courierPrice:"快递费用",
        courierIncreasePrice:"运费加价金额",prepaidMoney:"预付款",unpaidMoney:"待收尾款",
        deliverySite:"配送点",logisticsCompany:"物流公司",
        trackingNumber:"快递单号",orderNumber:"订单号",dockingDepartmentName:"对接部门名称",
        dockingTeamName:"对接班组名称",dockingStaffName:"对接人名称",
        dockingStaff:"对接人账号",customerLevel:"客户等级",
        orderTime:"录入时间",deliveryTime:"发货时间",
        orderStatus:"单据状态",receiptTime:"签收日期",refundsTime:"回款/退件时间"
    }
    let arry = [];
    let number = 0;
    array.forEach(ele => {
        number++;
        arry.push({
            number: number,name: ele.name,phone: ele.phone,wechat: ele.wechat,sex: ele.sex,
            ageRange: ele.age_range,address: ele.address,product_name: ele.product_name,
            packageName: ele.package_name,box_count: ele.box_count,bottle_count: ele.bottle_count,
            packageUnitPrice: ele.package_unit_price,package_total_price: ele.package_total_price,
            packageIncreasePrice: ele.package_increase_price,
            courierPrice: ele.courier_price,
            courierIncreasePrice: ele.courier_increase_price,prepaid_money: ele.prepaid_money,
            unpaidMoney: ele.unpaid_money,delivery_site: ele.delivery_site,
            logisticsCompany: ele.logistics_company,
            trackingNumber: ele.tracking_number,order_number: ele.order_number,
            dockingDepartmentName: ele.docking_department_name,dockingTeamName: ele.docking_team_name,
            dockingTeamName: ele.docking_staff_name,dockingStaffName: ele.docking_staff,
            customerLevel: ele.customer_level,orderTime: ele.order_time,deliveryTime: ele.delivery_time,
            orderStatus: ele.order_status,receiptTime: ele.receipt_time,refundsTime: ele.refunds_time,
        });
      });
      xlsx(arry, fields, text);
}

export function downXlsx(text){
    let fields = {
        order_number:"订单号",order_time:"录入时间",docking_department_name:"对接部门名称",docking_team_name:"对接班组名称",
        docking_staff_name:"对接人名称",docking_staff:"对接人账号",name:"客户姓名",phone:"客户电话",wechat:"微信号",
        marke:"备注",sex:"性别",age_range:"年龄",address:"客户地址",package_name:"套餐名称",product_name:"商品名称",box_count:"箱数",bottle_count:"购买瓶数",
        packageUnit_price:"套餐单价",package_total_price:"套餐总金额",package_increase_price:"套餐加价金额",courier_price:"费用",
        courier_increase_price:"运费加价金额",prepaid_money:"预付金额",unpaid_money:"待收货款",delivery_site:"配送点",logistics_company:"物流公司",
        tracking_number:"快递单号",delivery_time:"发货时间",order_status:"订单状态",receipt_time:"签收日期",refunds_time:"回款/退件时间"
    }
    let array = [
        {
            order_number:"bkj202008190001",
            order_time:"2020/8/19",
            docking_department_name:"电销一部一班",
            docking_team_name:"网销一部二班",
            docking_staff_name:"王业飞",
            docking_staff:"王业",
            name:"李某",
            phone:"18112322212",
            wechat:"18166562525",
            marke:"大客户，有钱，用酒需求大",
            sex:"男",
            age_range:"50-60",
            address:"河北省廊坊市三河市燕郊经济技术开发区冶金1局局机关",
            package_name:"（白酒）富盛天下收藏级一箱",
            product_name:"富盛天下收藏级",
            box_count:"3",
            bottle_count:"12",
            packageUnit_price:"799",
            package_total_price:"2397",
            package_increase_price:"0",
            courier_price:"0",
            courier_increase_price:"0",
            prepaid_money:"2000",
            unpaid_money:"397",
            delivery_site:"京东",
            logistics_company:"京东物流",
            tracking_number:"JDVD00288422239",
            delivery_time:"2019/11/09",
            order_status:"已回款",
            receipt_time:"2020/8/19",
            refunds_time:"2020/8/19"
        }
    ];
    xlsx(array, fields, text);
}