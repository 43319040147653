const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  permission_user: state => state.user.user,
  userId: state => state.user.userId,
  sign: state => state.user.sign,
  permission_btns: state => state.user.btns,
  permission_routes: state => state.user.routes,
}
export default getters
