import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'


export const asyncRoutes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name:'Login',
    component: () => import('@/views/login')
  },
  {
    path: '/template/set',
    name:'TemplateSet',
    key:'4-1',
    component: () => import('@/views/template_set')
  }
]

export const constantRoutes = [
  {
    path: '/user',
    name:'User',
    component: Layout,
    meta: { title: '微信用户', icon: 'el-icon-s-custom' },
    redirect: '/user/list',
    key:'1',
    type:'0',
    children: [
    {
      path: '/user/list',
      name:'UserList',
      key:'1',
      component: () => import('@/views/user_list')
    }]
  },
  {
    path: '/contract',
    name:'Contract',
    component: Layout,
    meta: { title: '合同管理', icon: 'el-icon-s-order' },
    redirect: '/contract/list',
    key:'2',
    type:'0',
    children: [
      {
        path: '/contract/list',
        name:'ContractList',
        key:'2',
        component: () => import('@/views/contract_list')
      },
      {
        path: '/contract/sign',
        name:'ContractSign',
        key:'2',
        component: () => import('@/views/set_sign')
      }
    ]
  },
  {
    path: '/comany',
    name:'Comany',
    component: Layout,
    meta: { title: '业务公司管理', icon: 'el-icon-s-management' },
    redirect: '/comany/list',
    key:'3',
    type:'0',
    children: [
    {
      path: '/comany/list',
      name:'ComanyList',
      key:'3',
      component: () => import('@/views/comany_list')
    }]
  },
  {
    path: '/template',
    name:'Template',
    component: Layout,
    meta: { title: '合同模版管理', icon: 'el-icon-s-claim' },
    redirect: '/template/list',
    key:'4',
    type:'0',
    children: [
    {
      path: '/template/list',
      name:'TemplateList',
      key:'4',
      component: () => import('@/views/template_list')
    }]
  },
  {
    path: '/recovery',
    name:'Recovery',
    component: Layout,
    meta: { title: '回收站', icon: 'el-icon-s-cooperation' },
    redirect: '/recovery/list',
    key:'5',
    type:'0',
    children: [
    {
      path: '/recovery/list',
      name:'RecoveryList',
      key:'5',
      component: () => import('@/views/recovery_list')
    }]
  },
  {
    path: '/sysComany',
    name:'SysComany',
    component: Layout,
    meta: { title: '企业管理', icon: 'el-icon-s-cooperation' },
    redirect: '/sysComany/list',
    key:'6',
    type:'0',
    children: [
    {
      path: '/sysComany/list',
      name:'SysComanyList',
      key:'6',
      component: () => import('@/views/sys_comany_list')
    }]
  },
  {
    path: '/account',
    name:'Account',
    component: Layout,
    meta: { title: '账号管理', icon: 'el-icon-s-cooperation' },
    redirect: '/account/list',
    key:'7',
    type:'0',
    children: [
    {
      path: '/account/list',
      name:'AccountList',
      key:'7',
      component: () => import('@/views/sys_account_list')
    }]
  },
  {
    path: '/news',
    name:'News',
    component: Layout,
    meta: { title: '消息模版管理', icon: 'el-icon-chat-dot-round' },
    redirect: '/news/list',
    key:'8',
    type:'0',
    children: [
    {
      path: '/news/list',
      name:'NewsList',
      key:'8',
      component: () => import('@/views/sys_news_list')
    }]
  }/* ,
  {
    path: '/msg',
    name:'Msg',
    component: Layout,
    meta: { title: '短信模版管理', icon: 'el-icon-phone' },
    redirect: '/msg/list',
    key:'9',
    type:'0',
    children: [
    {
      path: '/msg/list',
      name:'MsgList',
      key:'9',
      component: () => import('@/views/sys_msg_list')
    }]
  } */
  
  /*{
    path: '/pdfs',
    name:'Pdfs',
    component: Layout,
    meta: { title: '预览合同', icon: 'el-icon-user' },
    redirect: '/pdfs/list',
    key:'4',
    type:'0',
    children: [
    {
      path: '/pdfs/list',
      name:'PdfsList',
      key:'4',
      component: () => import('@/views/mobile/user_contract')
    }]
  }*/
]


const createRouter = () => new Router({
  //scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  routes: [...constantRoutes,...asyncRoutes]
})

const router = createRouter()

export default router
