var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "模版类别列表",
            visible: _vm.temp.tempType.visible,
            "before-close": _vm.closeTempType,
            "close-on-click-modal": false,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.temp.tempType, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [
                  _c("span"),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.addTemp("tempTypeInner")
                        }
                      }
                    },
                    [_vm._v("新增类别")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "类别名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "createTime", label: "创建日期" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.edit(scope.row, "tempTypeInner")
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.del(scope.row, "tempTypeInner")
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-pagination", {
                    staticClass: "page-footer",
                    attrs: {
                      background: "",
                      layout: "total,prev,pager,next,sizes,jumper",
                      total: _vm.total,
                      "page-sizes": [10, 20, 30, 40, 50, 100]
                    },
                    on: {
                      "current-change": _vm.handlePageChange,
                      "size-change": _vm.handleSizeChange
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                title: "新增类别",
                visible: _vm.temp.tempTypeInner.visible,
                "append-to-body": "",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.temp.tempTypeInner, "visible", $event)
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.tempTypeForm, size: "mini" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类别名称",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "请输入类别名称",
                            trigger: "bulr"
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.tempTypeForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.tempTypeForm, "name", $$v)
                          },
                          expression: "tempTypeForm.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.temp.tempTypeInner.visible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.tempTypeSubmit("ruleForm")
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增模版",
            visible: _vm.temp.temp.visible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.temp.temp, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "tempForm", attrs: { model: _vm.tempForm, size: "mini" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "模版名称",
                    prop: "templateName",
                    rules: [
                      {
                        required: true,
                        message: "请输入模版名称",
                        trigger: "bulr"
                      }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.tempForm.templateName,
                      callback: function($$v) {
                        _vm.$set(_vm.tempForm, "templateName", $$v)
                      },
                      expression: "tempForm.templateName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "模版类别（支持模糊搜索）",
                    prop: "templateTypeId",
                    rules: [
                      {
                        required: true,
                        message: "请选择模版类别",
                        trigger: "change"
                      }
                    ]
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.typeChange($event)
                        }
                      },
                      model: {
                        value: _vm.tempForm.templateTypeId,
                        callback: function($$v) {
                          _vm.$set(_vm.tempForm, "templateTypeId", $$v)
                        },
                        expression: "tempForm.templateTypeId"
                      }
                    },
                    _vm._l(_vm.tempTypeData, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.name }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "业务公司",
                    prop: "companyId",
                    rules: [
                      {
                        required: true,
                        message: "请选择业务公司",
                        trigger: "change"
                      }
                    ]
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", clearable: "" },
                      on: { change: _vm.companyChange },
                      nativeOn: {
                        click: function($event) {
                          return _vm.companyIdChange($event)
                        }
                      },
                      model: {
                        value: _vm.tempForm.companyId,
                        callback: function($$v) {
                          _vm.$set(_vm.tempForm, "companyId", $$v)
                        },
                        expression: "tempForm.companyId"
                      }
                    },
                    _vm._l(_vm.comanyList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.name }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "模版（格式doc）",
                    prop: "file",
                    rules: [
                      {
                        required: true,
                        message: "请上传模版",
                        trigger: "change"
                      }
                    ]
                  }
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadPdf",
                      staticClass: "upload-demo",
                      attrs: {
                        "auto-upload": false,
                        "file-list": _vm.fileList,
                        action: "#",
                        "on-change": _vm.onChangeFile,
                        "before-remove": _vm.beforeRemove
                      },
                      model: {
                        value: _vm.tempForm.file,
                        callback: function($$v) {
                          _vm.$set(_vm.tempForm, "file", $$v)
                        },
                        expression: "tempForm.file"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "mini",
                            type: "primary"
                          },
                          slot: "trigger"
                        },
                        [_vm._v("选取文件")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.temp.temp.visible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.tempSubmit("tempForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "temp-mian" }, [
        _c(
          "div",
          { staticClass: "temp-tree" },
          [
            _c(
              "div",
              { staticClass: "classify-mian" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%", margin: "10px 0" },
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "选择业务公司"
                    },
                    on: { change: _vm.companyChange },
                    nativeOn: {
                      click: function($event) {
                        return _vm.companyIdChange($event)
                      }
                    },
                    model: {
                      value: _vm.companyId,
                      callback: function($$v) {
                        _vm.companyId = $$v
                      },
                      expression: "companyId"
                    }
                  },
                  _vm._l(_vm.comanyList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { value: item.id, label: item.name }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "classify-mian" }, [
              _c("div", { staticClass: "classify-title" }, [
                _vm._v("分类信息")
              ]),
              _vm.userType === "3"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.addTemp("tempType")
                            }
                          }
                        },
                        [_vm._v("新增类别")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.addTemp("temp")
                            }
                          }
                        },
                        [_vm._v("新增模版")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("el-tree", {
              attrs: { data: _vm.tempTypeTab, props: _vm.defaultProps },
              on: { "node-click": _vm.handleNodeClick }
            })
          ],
          1
        ),
        _c("div", { staticClass: "temp-inner" }, [
          _c(
            "ul",
            { staticClass: "pdf-uis" },
            _vm._l(_vm.contractTemp, function(item, index) {
              return _c("li", { key: index, staticClass: "pdf-lis" }, [
                _c("div", { staticClass: "pdf-inner" }, [
                  _c("h1", { staticClass: "pdf-title" }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _c("div", { staticClass: "pdf-coer" }, [
                    _c("img", { attrs: { src: _vm.pdfImg } })
                  ]),
                  _vm.userType === "3"
                    ? _c(
                        "div",
                        { staticClass: "pdf-footer" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "left" },
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.setPdfTemp(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " 配置占位符（" +
                                  _vm._s(
                                    item.placeholderStatus === 0
                                      ? "未配置"
                                      : "已配置"
                                  ) +
                                  "）"
                              )
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.delPdfStatus(item)
                                }
                              }
                            },
                            [_vm._v(" 删除 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.setPdfStatus(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.status === 1 ? "正常" : "禁用") +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            }),
            0
          ),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.flag,
                expression: "flag"
              }
            ],
            staticStyle: {
              width: "280px",
              margin: "120px auto",
              display: "block"
            },
            attrs: { src: _vm.emtps }
          })
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }