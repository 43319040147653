var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix flexs",
              class: _vm.goTopShow === true ? "pages-scrolltop" : "",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c(
                "div",
                { staticClass: "pages" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        disabled: _vm.currentPage == 1
                      },
                      on: {
                        click: function($event) {
                          return _vm.changePdfPage(0)
                        }
                      }
                    },
                    [_vm._v("上一页")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(_vm.currentPage) +
                      " / " +
                      _vm._s(_vm.pageCount) +
                      " "
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        disabled: _vm.currentPage == _vm.pageCount
                      },
                      on: {
                        click: function($event) {
                          return _vm.changePdfPage(1)
                        }
                      }
                    },
                    [_vm._v("下一页")]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.goPath }
                },
                [_vm._v("返回列表")]
              )
            ],
            1
          ),
          _c("pdf", {
            attrs: { src: _vm.pdfUrl, page: _vm.currentPage },
            on: {
              "num-pages": function($event) {
                _vm.pageCount = $event
              },
              "page-loaded": function($event) {
                _vm.currentPage = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }