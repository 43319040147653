<template>

  <div class="login-container" :style="{backgroundImage:'url('+bgImg+')'}">

    

    <img class="login-bg-left-img" :src="loginBgLeft"/>
    <img class="login-bg-top-right-img" :src="loginBgTopRight"/>
    <img class="login-bg-btm-right-img" :src="loginBgBtmRight"/>
    <el-form
      class="login-form"
      :model="parmas" ref="loginForm">
      <div class="login-left" :style="{backgroundImage:'url('+loginModelLeft+')'}"> </div>
      <div class="login-right">
        <img class="login-top-right-img" :src="loginModelTopRight"/>
        <img class="login-btm-right-img" :src="loginModelBtmRight"/>
        <h3 class="login-form-title">欢迎登录～</h3>
        <!-- <h3 class="login-form-title-max">合同签约管理平台</h3> -->
        <h3 class="login-form-title-max">企服通签约平台</h3>
        <el-form-item class="ele-form-item" prop="username" :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' },
          {validator: (rule, val, d) => {
                  const reg = /\s/
                  val && reg.test(val) ? d(true) : d()
                },
                message: '用户名不能包含空格'}]">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            placeholder="请输入账户"
            auto-complete="on"
            v-model="parmas.username"
          />
        </el-form-item>

        <el-form-item class="ele-form-item" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]">
          <span class="svg-container">
             <svg-icon icon-class="password" />
          </span>
          <el-input
            v-model="parmas.password"
            :key="passwordType"
            ref="password"
            :type="passwordType"
            placeholder="请输入密码"
            auto-complete="on"
          />
          <span class="show-pwd" @click="showPwd">
             <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
        </el-form-item>
        <el-button
          type="primary"
          block
          class="login-btn"
          className="button-color-dust"
          @click="handleLogin('loginForm')"
          size="large"
        >登录</el-button>
      </div>
    </el-form>
   <!--  <p class="footer-content">重庆纳展企业服务有限公司版权所有  <a style="text-decoration: underline;" target="_blank" href="https://beian.miit.gov.cn/">渝ICP备2021002807号-1</a> </p> -->
  </div>
  
</template>

<script>
import login_bg from "@/assets/login_bg.png"
import login_bg_left from "@/assets/login_bg_left.png"
import login_bg_top_right from "@/assets/login_bg_top_right.png"
import login_bg_btm_right from "@/assets/login_bg_btm_right.png"
import login_model_left from "@/assets/login_model_left.png"
import login_model_top_right from "@/assets/login_model_top_right.png"
import login_model_btm_right from "@/assets/login_model_btm_right.png"
export default {
  name: "Login",
  data() {
    return {
      bgImg: login_bg,
      loginBgLeft:login_bg_left,
      loginBgTopRight:login_bg_top_right,
      loginBgBtmRight:login_bg_btm_right,
      loginModelLeft: login_model_left,
      loginModelTopRight:login_model_top_right,
      loginModelBtmRight:login_model_btm_right,
      passwordType: "password",
      parmas: {username: '',password: ''}
    };
  },
  created() {
  },
  mounted() {
    window.addEventListener('keydown',this.keyDown);
  },
  destroyed(){
    window.removeEventListener('keydown',this.keyDown,false);
  },
  methods: {
    keyDown(e){
			if(e.keyCode == 13){
				this.handleLogin('loginForm');
			}
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin(loginForm) {
      this.$refs[loginForm].validate(async valid =>{
       if (valid) {
          this.$store.dispatch("user/login", this.parmas).then(res => {
            this.$router.push({path:"/user",name:"User"});
          }).catch(() => {
              this.parmas.userName = ""; 
              this.parmas.pwd = "";
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style>

 .login-btn{
   margin-top: 40px;
   margin-bottom: 10px;
   width: 100%;
 }
.login-container {
  min-width: 1000px;
  min-height: 100%;
  background-color: #eefbf6;
  background-size: cover;
  position: relative;
}
.login-bg-top-right-img{
  position: absolute;
  right: 0;
  top: 0;
  width: 240px;
  height: 175px;
}
.login-bg-btm-right-img{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 654px;
  height: 148px;
}
.login-bg-left-img{
  position: absolute;
  left: 0;
  top: 0;
  width: 854px;
  height: 100%;
}
.login-container .ant-form-item-children{
    border-bottom: 1px solid #dbdbdb;
    display: block;
    margin-bottom: 6px;
}
.login-form {
    width: 1000px;
    height: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -280px;
    margin-left: -500px;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 80px 0 rgba(15,80,57,.17);
    display: flex;
  }
  .login-left{
    flex: 0 1 560px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 32px 0 0 32px;
  }
  .login-right {
      flex: auto;
    position: relative;
    padding: 80px 36px 0;
    }
    .login-top-right-img{
      position: absolute;
      top: 0;
      right: 0;
      width: 171px;
      height: 116px;
      background-size: 100% 100%;
    }
    .login-btm-right-img{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 435px;
      height: 106px;
      background-size: 100% 100%;
    }


.login-form-title {
    position: relative;
    padding-left: 12px;
    font-size: 16px;
    line-height: 18px;
    color: #999;
    padding-bottom: 12px;
      }
.login-form-title::before {
        content: "";
        position: absolute;
        left: 0;
        background: #4fb2ff;
        width: 3px;
        height: 14px;
        top: 1px;
        border-radius: 2px;
      }
.login-form-title-max{
    font-size: 24px;
    font-weight: 600;
    color: #4fb2ff;
    line-height: 24px;
    padding-bottom: 44px;
}
.ele-form-item {
        background: transparent;
      }
      .login-container input {
          background: transparent;
          border: 0px !important;
          -webkit-appearance: none;
          border-radius: 0px;
          color: #000;
          height: 47px;
          caret-color: #000;
          
        }
        input:-webkit-autofill {
            box-shadow: 0 0 0px 1000px #f7f6f9 inset !important;
            -webkit-text-fill-color: #000 !important;
          }
 .login-container .el-input {
          padding-left: 35px;
          background: #f7f6f9;
        }
.show-pwd {
          position: absolute;
          right: 10px;
          font-size: 16px;
          color: #889aa4;
          cursor: pointer;
          user-select: none;
          top: 50%;
          transform: translate(0, -50%);
        }
.svg-container {
    position: absolute;
    left: 0px;
    z-index: 99;
    width: 40px;
    text-align: center;
    font-size: 22px;
    color: #4fb2ff;
    top: 50%;
    transform: translate(0%, -50%);
}
.svg-container .anticon{
    font-size: 20px;
    vertical-align: middle;
}
.footer-content{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 13px;
}
</style>
